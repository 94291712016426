import React, {useEffect} from "react";
import './style.scss';

const TimeButtons = ({ timeIntervals, activeInterval, onIntervalChange, page }) => {
    return (
        <div className={`timeButtons ${page === 'main' && 'main'}`}>
            {timeIntervals.map(interval => (
                <div
                    key={interval}
                    onClick={() => onIntervalChange(interval)}
                    className={`btn ${page === 'analytics' && activeInterval === interval ? 'activeSec' : page === 'main' && activeInterval === interval ? 'active' : ''}`}
                >
                    {interval}
                </div>
            ))}
        </div>
    )
}

export default TimeButtons;
