import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import store from './store/store';
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { ToastContainer, Zoom } from "react-toastify";

// const chains = [arbitrum, mainnet, polygon]
// ! CHANGE THIS BACK LATER
const projectId = 'TestID'
const metadata = {
    name: 'Sugar Heads',
    description: 'The Worlds First NFT Backed by a basket of digital assets',
    url: 'https://sugarheads.io/',
    icons: ['%PUBLIC_URL%/logo192.png']
  }

  
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    projectId
  })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
      <Provider store={store}>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
        <App />
      </Provider>
    </BrowserRouter>
);
reportWebVitals();
