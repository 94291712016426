import "./style.scss";

const PixelPage = () => {
  return (
    <div class="pixel-page-wrap">
      <section class="hero">
        <div class="container">
          <div class="hero-wrap">
            <img
              src="https://uploads-ssl.webflow.com/663d1241e2f31fd3a4af7229/666b1e47e1095a2fb77bd933_IMG_3011.PNG"
              loading="lazy"
              alt=""
              class="sh-hero-logo"
            />
            <p class="hero-p">10,000 unique NFTs on PulseChain</p>
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d143883bc97085ddc4240_sugar-skull.png"
              loading="lazy"
              alt=""
              class="hero-skull"
            />
            <a href="https://www.mint.sugarheads.io/" class="btn-mint w-button">
              mint sugarheads pixels
            </a>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <div class="pixel-phases-outer">
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/6669a7345417b5c4f52cef83_IMG_3364%20(1).gif"
              loading="lazy"
              alt=""
              class="pixels-gif"
            />
            <div class="section-h center">launch phases</div>
            <div class="launch-phase-outer">
              <div class="launch-phase-wrap">
                <div class="launch-phase-h">day 1 - July 5th</div>
                <div class="launch-phase-p">
                  OG Sugar Heads Holders Mint 1:1 <br />‍<br />
                  FREE 5555+ HOA Holders Mint 1 Free (1000 Limit)
                </div>
              </div>
              <div class="launch-phase-wrap">
                <div class="launch-phase-h color-1">day 2 - July 6th</div>
                <div class="launch-phase-p">
                  Public Mint - 500k $PLS+
                  <br />
                  <br />
                  HOA Holders Get 33% Discount - 335K $PLS (10 Max Per Wallet)
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <div class="section-grid">
            <div
              id="w-node-cdfcb27d-85ec-7117-4369-487cc80e0612-a4af7233"
              class="section-gw flex-center"
            >
              <img
                src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/66671e360f03c99fa3c57358_Untitled%20design%20(5).png"
                loading="lazy"
                data-w-id="884b2bce-bb62-f460-c892-fb12b6ed1c0c"
                sizes="(max-width: 479px) 40vw, (max-width: 767px) 108.3125px, (max-width: 991px) 29vw, 36vw"
                alt=""
                srcset="
                https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/66671e360f03c99fa3c57358_Untitled%20design%20(5)-p-500.png  500w,
                https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/66671e360f03c99fa3c57358_Untitled%20design%20(5)-p-800.png  800w,
                https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/66671e360f03c99fa3c57358_Untitled%20design%20(5).png       1080w
              "
                class="allocation-img"
              />
            </div>
            <div
              id="w-node-_4d010225-c814-5080-6f82-a3ba49e64b72-a4af7233"
              class="section-gw"
            >
              <div class="section-h">Fund allocation</div>
              <div class="section-p">
                The funds from the mint will be allocated to three different
                areas of the project.
              </div>
              <div class="allocation-outer">
                <div
                  data-w-id="87f203b2-9010-036c-7cd9-775b21741b14"
                  class="allocation-wrap"
                >
                  <div class="allocation-h color-1">33%</div>
                  <div class="allocation-p">
                    pulseChain centric community vault assets
                  </div>
                </div>
                <div
                  data-w-id="4ee69002-33e3-83d0-113a-81d2adb1890d"
                  class="allocation-wrap"
                >
                  <div class="allocation-h color-2">33%</div>
                  <div class="allocation-p">
                    web3 game development - Code name:
                    <span class="text-span">sugarheads road rage</span>
                  </div>
                </div>
                <div
                  data-w-id="1717b54d-2c38-64e0-4c69-209461c04871"
                  class="allocation-wrap"
                >
                  <div class="allocation-h">33%</div>
                  <div class="allocation-p">starving artist fund</div>
                </div>
              </div>
              <a
                href="https://www.mint.sugarheads.io/"
                class="btn-mint w-button"
              >
                mint sugarheads pixels
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="section full-section">
        <div class="container full-width">
          <div class="gallery-preview">
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d17c900610b8d6f2b3f_sugar-1.jpg"
              loading="lazy"
              id="w-node-d7ca3e09-c2f0-685d-d2fb-ac4e80dc6a09-a4af7233"
              sizes="(max-width: 479px) 47vw, (max-width: 991px) 32vw, 33vw"
              alt=""
              srcset="
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d17c900610b8d6f2b3f_sugar-1-p-500.jpg  500w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d17c900610b8d6f2b3f_sugar-1-p-800.jpg  800w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d17c900610b8d6f2b3f_sugar-1.jpg       1280w
            "
              class="gallery-img"
            />
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67efddcacfc5e9fb9d_sugar-2.jpg"
              loading="lazy"
              id="w-node-_18d7cf89-d64a-feb0-c3f9-4871f5d83631-a4af7233"
              sizes="(max-width: 479px) 47vw, (max-width: 991px) 32vw, 33vw"
              alt=""
              srcset="
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67efddcacfc5e9fb9d_sugar-2-p-500.jpg  500w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67efddcacfc5e9fb9d_sugar-2-p-800.jpg  800w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67efddcacfc5e9fb9d_sugar-2.jpg       1280w
            "
              class="gallery-img"
            />
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67de04c5ceab14eb68_sugar-8.jpg"
              loading="lazy"
              id="w-node-_6b02e90b-bbf3-553e-63ba-c94f8d2098e4-a4af7233"
              sizes="(max-width: 479px) 47vw, (max-width: 991px) 32vw, 33vw"
              alt=""
              srcset="
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67de04c5ceab14eb68_sugar-8-p-500.jpg  500w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67de04c5ceab14eb68_sugar-8-p-800.jpg  800w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67de04c5ceab14eb68_sugar-8.jpg       1280w
            "
              class="gallery-img"
            />
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d673b4d4ca2f160897b_sugar-4.jpg"
              loading="lazy"
              id="w-node-f185fb58-3f7b-9af5-8e67-cb8a58b92589-a4af7233"
              sizes="(max-width: 479px) 47vw, (max-width: 991px) 32vw, 33vw"
              alt=""
              srcset="
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d673b4d4ca2f160897b_sugar-4-p-500.jpg  500w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d673b4d4ca2f160897b_sugar-4-p-800.jpg  800w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d673b4d4ca2f160897b_sugar-4.jpg       1280w
            "
              class="gallery-img"
            />
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d6794054280f270ebba_sugar-9.jpg"
              loading="lazy"
              id="w-node-f05b75ea-a158-dd35-887e-6ffff3527c88-a4af7233"
              sizes="(max-width: 479px) 47vw, (max-width: 991px) 32vw, 33vw"
              alt=""
              srcset="
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d6794054280f270ebba_sugar-9-p-500.jpg  500w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d6794054280f270ebba_sugar-9-p-800.jpg  800w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d6794054280f270ebba_sugar-9.jpg       1280w
            "
              class="gallery-img"
            />
            <img
              src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67d5b32ce60cc88103_sugar-7.jpg"
              loading="lazy"
              id="w-node-_684a04f2-0292-21b4-452c-b607ba0b2c33-a4af7233"
              sizes="(max-width: 479px) 47vw, (max-width: 991px) 32vw, 33vw"
              alt=""
              srcset="
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67d5b32ce60cc88103_sugar-7-p-500.jpg  500w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67d5b32ce60cc88103_sugar-7-p-800.jpg  800w,
              https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1d67d5b32ce60cc88103_sugar-7.jpg       1280w
            "
              class="gallery-img"
            />
          </div>
        </div>
        <img
          src="https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d1e1a666db633b67c9e26_sugar-crown.png"
          loading="lazy"
          alt=""
          class="sugar-crown"
        />
      </section>
    </div>
  );
};

export default PixelPage;
