import React, { useEffect, useState } from "react";
import './style.scss';
import smileImage from '../../../../images/analyticsPage/smileImage.png';
import dollarImage from '../../../../images/analyticsPage/dollarImage.png';
import scullImage from '../../../../images/analyticsPage/scullImage.png';
import Button from "../../../../components/button/button";
import { useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
// import { useDispatch, useSelector } from "react-redux/es/hooks/useSelector";
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
import { getClaimNFT, getClaimAmount, getContractABI } from "../../../../store/UserSlices/UserSlices.js"
import { errors, ethers } from "ethers";
import Web3 from 'web3';
import { toast } from "react-toastify";
// import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
// import { useWeb3ModalSigner } from '@web3modal/ethers5/react'


const RewardsSection = ({ walletConnect, setWalletConnect }) => {
    const dispatch = useDispatch();
    const lockTime = useSelector((data) => data?.userReducer?.time?.result?.lockTime);
    const contractAddress = useSelector((data) => data?.addressReducer);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [animatedItem, setAnimatedItem] = useState(null);
    const { signer, walletProvider, WalletProviderType } = useWeb3ModalSigner();
    const [claimableNFT, setClaimableNFT] = useState(null);
    const [claimableAmount, setClaimAmount] = useState(null);
    const [ABI, setABI] = useState(null)
    const [web3, setWeb3] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [time, setTime] = useState(Math.floor(Date.now() / 1000));

    const [countdown, setCountdown] = useState({});
    useEffect(() => {
        if (isConnected) {

            const data = {
                address: contractAddress?._id,
                provider: walletProvider?.getSigner(address)
            }
           
            dispatch(getClaimAmount(data)).then((res) => {
                setClaimAmount(res?.payload?.result?.amount)
            });
        }
    }, [dispatch, isConnected, contractAddress])











    const handleTap = async (item) => {
        
        
        if (time > lockTime) {
            setIsLoading(true)
            setTimeout(() => {
                setAnimatedItem(item);
                // setAnimatedItem(null);
            }, 500);

            if (isConnected) {
                const datas = {
                    address: contractAddress?._id,
                    provider: walletProvider?.getSigner(address),
                };

                try {
                    // Use await to wait for the asynchronous dispatch functions
                    const claimNFTResult = await dispatch(getClaimNFT(datas));
                    setClaimableNFT(claimNFTResult?.payload?.result?.nfts);
                    if (claimNFTResult?.payload?.result?.nfts && claimNFTResult?.payload?.result?.nfts) {

                        const contractABIResult = await dispatch(getContractABI(contractAddress?._id));
                        setABI(contractABIResult?.payload?.result?.jsonData);
                        if (contractABIResult?.payload?.result?.jsonData) {
                            const daiContract = new ethers.Contract(
                                contractAddress.address,
                                JSON.parse(JSON.stringify(contractABIResult.payload.result.jsonData)),
                                walletProvider
                            );
                           

                            const daiWithSigner = daiContract.connect(walletProvider?.getSigner(address));

                            try {
                                const claimResult = await daiWithSigner.claim(claimNFTResult?.payload?.result?.nfts, {
                                    gasLimit: 3000000,
                                });
                            
                                toast.success("Reward Claimed")
                                setIsLoading(false)
                            } catch (claimError) {
                                console.error("Error claiming:", claimError);
                                if (claimError.code == 'ACTION_REJECTED') {
                                    toast.error("Transaction Rejected by User")
                                }
                                else if (claimError.code == 'UNKNOWN_ERROR') {
                                    toast.error("Unknow Error Occurred")
                                }
                                else if (claimError.code == 'TIMEOUT') {
                                    toast.error("Timeout Duration Error")
                                }
                                else if (claimError.code == 'CANCELLED') {
                                    toast.error("Transaction Cancelled")
                                }
                                else if (claimError.code == 'INSUFFICIENT_FUNDS') {
                                    toast.error("Insufficient Funds")
                                }
                                else {
                                    toast.error(claimError.message)
                                }
                                setIsLoading(false)
                                // Handle claim error
                            }
                        }

        
                    }
                } catch (error) {
                    console.error("Error:", error);
                    setIsLoading(false)
                    // Handle the error
                }
            }
        }
        else {
            toast.error("You can claim reward after lock time ends")
        }
    };





    useEffect(() => {
        if (time < lockTime) {
            const lockTimeMoment = moment(lockTime * 1000);

            // Create an interval to update the remaining time every second
            const intervalId = setInterval(() => {
                const now = moment();
                const duration = moment.duration(lockTimeMoment.diff(now));
    

                // Calculate days, hours, and minutes separately
                const days = Math.floor(duration.asDays());
                const hours = Math.floor(duration.asHours()) % 24;
                const minutes = Math.floor(duration.asMinutes()) % 60;

                // Set the time state with the remaining time
                setCountdown({ days, hours, minutes });
            }, 1000);
            // Clean up the interval when the component unmounts
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [lockTime]);
    // let { isLoading, isSuccess } = useWaitForTransaction({
    //     hash: data?.hash,
    // })
    return (
        <div className='rewardsSectionWrapper'>
            <div className={`notification ${isConnected ? 'hidden' : ''}`}>
                <div className='notification__title'>
                    Open your wallet to become the owner
                </div>
                <div className='notification__description'>
                    View your claimable rewards!

                    Connect your digital wallet to access the claimable assets linked to your NFT. In the Sugarheads ecosystem, each NFT comes with comparable rewards that become available for claiming once the vault unloc
                </div>
                <Button text='Connect Wallet' />
            </div>
            <div className='rewardsSection limitWidth'>
                <div className={`rewardsSection__header ${isConnected ? '' : 'blur'}`}>
                    <div className={`rewardsSection__header-title`}>
                        Rewards
                        <div className='rewardsSection__header-title-imageWrap'>
                            <img src={smileImage} alt='smileImage' />
                        </div>
                    </div>
                    <div className='rewardsSection__header-description'>
                        The rewards contained within this vault which are ERC20 assets, provide Sugarheads holders to a entitled, proportionate share of the vault. There are 1750 unique digital tokens, or NFTs (Non-Fungible Tokens), each with a right to a specific portion of the assets in this vault. The value that each NFT holder can claim corresponds to their share of the total assets in the vault.

                        If the market is looking good, Sugarheads are looking even sweeter.
                    </div>
                </div>
                <div className={`rewardsSection__timerWrapper ${isConnected ? '' : 'blur'}`}>
                    <img className='dollarImage' src={dollarImage} alt='dollarImage' />
                    <img className='scullImage' src={scullImage} alt='scullImage' />
                    <div className='rewardsSection__timerWrapper-claim'>
                        Claim: ${claimableAmount ? claimableAmount : " 0"}
                    </div>
                    <div className='rewardsSection__timerWrapper-timer'>
                        <div className='rewardsSection__timerWrapper-timer-item'>
                            <div>{time < lockTime ? countdown.days : "0"}</div>
                            <div className='rewardsSection__timerWrapper-timer-item-name'>Days</div>
                        </div>
                        <div className='rewardsSection__timerWrapper-timer-item'>
                            <div>{time < lockTime ? countdown.hours : "0"}</div>
                            <div className='rewardsSection__timerWrapper-timer-item-name'>Hours</div>
                        </div>
                        <div className='rewardsSection__timerWrapper-timer-item'>
                            <div>{time < lockTime ? countdown?.minutes : "0"}</div>
                            <div className='rewardsSection__timerWrapper-timer-item-name'>Minutes</div>
                        </div>
                    </div>
                </div>
                <div className={`rewardsSection__btnWrap ${isConnected ? '' : 'blur'} `}>
                    <div disabled={isLoading} onClick={!isLoading ? () => handleTap('claim') : null} className={`rewardsSection__btnWrap-btn ${animatedItem === 'claim' ? 'animate' : ''}`}>
                        {isLoading ? "claiming..." : "claim "}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RewardsSection;
