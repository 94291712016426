import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import  userReducer from './UserSlices/UserSlices.js';
import addressReducer from './UserSlices/ContractSlices.js';
import contractSlice from "./UserSlices/AddressSlices.js"


const rootReducer = combineReducers({
    userReducer: userReducer,
    addressReducer : addressReducer ,
    contractSlice:contractSlice
  });


  const store = configureStore({
    reducer: rootReducer,
  });
  
  export default store;