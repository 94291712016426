import React, { useEffect, useState } from "react";
import "./style.scss";

const SugarButton = ({ text }) => {
  const [animatedItem, setAnimatedItem] = useState(null);
  const baseClassName = `button button--${text
    .toLowerCase()
    .replace(/ /g, "")}`;
  const animationClass = animatedItem === text ? "animate" : "";
  const className = `${baseClassName} ${animationClass}`;

  const handleClick = async () => {
    setAnimatedItem(text);

    window.open(
      "/pixels",
      "_blank",
      "noreferrer"
    );

    setTimeout(() => {
      setAnimatedItem(null);
    }, 500);
  };

  return (
    <div onClick={handleClick} className={className}>
      Mint Sugar Heads Pixels
    </div>
  );
};

export default SugarButton;
