import React, { useRef, useState } from "react";
import './style.scss';
import scull from '../../../../images/mainPage/scull.png';
import nftImage1 from "../../../../images/mainPage/nft1.png";
import nftImage2 from "../../../../images/mainPage/nft2.png";
import nftImage3 from "../../../../images/mainPage/nft3.png";
import arrowL from '../../../../images/mainPage/teamArrowL.svg'
import arrowR from '../../../../images/mainPage/teamArrowR.svg'
import nftBG from '../../../../images/mainPage/nftTeamBG.png';
import twitIcon from '../../../../images/mainPage/twitIcon.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import "swiper/css/pagination"

import { useDispatch, useSelector } from 'react-redux';

import { adminGetFeaturedTeams } from "../../../../store/UserSlices/UserSlices.js";
import { useEffect } from "react";


const TeamSection = () => {
    const [animatedItem, setAnimatedItem] = useState(null);
    const sliderRef = useRef(null);
    const dispatch = useDispatch();
    const [sliderArr1, setSliderArr1] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [speeds, setSpeed] = useState(0);

    useEffect(() => {
        dispatch(adminGetFeaturedTeams()).then((res) => {
            const arr = res?.payload?.result?.team?.docs
            if (arr?.length == 3){
                setSliderArr1([...arr.slice(-3), ...arr]);
            }else{
                setSliderArr1(arr);
            }
            if (sliderRef.current && sliderRef.current.swiper) {
                const swiper = sliderRef.current.swiper;
                swiper.slideTo(1, 0);
            }
        })

    }, [dispatch, sliderRef]);

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.realIndex);
    };

    const currentMaxWidth = window.innerWidth;
    const isLastSlide = currentSlide === sliderArr1?.length - 1;
    const isFirstSlide = currentSlide === 0;



    const handleTap = (item) => {
        setAnimatedItem(item);
        setTimeout(() => {
            setAnimatedItem(null);
        }, 500);
    };

    const handlePrev = (item) => {
        handleTap(item)
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slidePrev();
        }
    };

    const handleNext = (item) => {
        handleTap(item)
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slideNext();
        }
    };
    const checkWidth = () => {
        if (currentMaxWidth >= 1000) {
            return 0
        } else if (currentMaxWidth >= 768 && currentMaxWidth <= 999) {
            return 30
        } else if (currentMaxWidth >= 480 && currentMaxWidth <= 767) {
            return 25
        } else if (currentMaxWidth >= 320 && currentMaxWidth <= 479) {
            return 10
        }
    }

    return (
        <>
            {/* <div className='teamSectionWrapper'>
            <div className='teamSection limitWidthSec'>
                <div className='teamSection__header'>
                    <div className='teamSection__header-title'>
                        THE TEAM
                        <img src={scull} />
                    </div>
                </div>
                <div className='teamSection__slider'>
                {sliderArr1?.length >= 3 ?
                    <Swiper
                        ref={sliderRef}
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        loopedSlides={2}
                        slidesPerView={currentMaxWidth >= 1000 ? 3 : 'auto'}
                        spaceBetween={checkWidth()}
                        speed={1000}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        pagination={false}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                        allowTouchMove={window.innerWidth > 1080}
                        initialSlide={0}
                    >
                        {sliderArr1 && sliderArr1?.map((image, index) => (
                            <SwiperSlide key={index}  >
                                {({ isActive }) => (
                                <>
                                <img className='image' key={index} src={`${process.env.REACT_APP_IMG_URL}/${image.fileName}`} style={{borderRadius:"15px"}} />
                                <div className='activeSlide' onClick={()=>{window.open(image.twitterLink, '_blank', 'noreferrer')}}>
                                    <img src={twitIcon}/>
                                    <span>{image.name}</span>
                                </div>
                                </>
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                     :
                     <div className='teamSection__teamItemWrap'>
                         {sliderArr1?.map((item, key) => {
                             return (
                                 <div key={key} className='teamSection__teamItemWrap-item'>
                                     <img className='image' src={item.img} />
                                     <div className='activeSlide'>
                                         <img src={twitIcon}/>
                                         <span>{item.name}</span>
                                     </div>
                                 </div>
                             )
                         })}
                     </div>
                 }
                </div>
                {sliderArr1?.length >= 3 &&
                    <div className='teamSection__btnWrap'>
                        <img className={`teamSection__btnWrap-img ${animatedItem === 'arrowL' ? 'animate' : ''} ${sliderArr1?.length < 5 && isFirstSlide ? 'disabled-btn' : ''}`} onClick={() => handlePrev('arrowL')} src={arrowL}/>
                        <img className={`teamSection__btnWrap-img ${animatedItem === 'arrowR' ? 'animate' : ''} ${sliderArr1?.length < 5 && isLastSlide ? 'disabled-btn' : ''}`} onClick={() => handleNext('arrowR')} src={arrowR}/>
                    </div>
                }
            </div>
        </div> */}
            <div className='teamSectionWrapper'>
                <div className='teamSection limitWidthSec'>
                    <div className='teamSection__header'>
                        <div className='teamSection__header-title'>
                            THE TEAM
                            <img src={scull} />
                        </div>
                    </div>
                    {sliderArr1?.length >= 3 ?
                        <div className='teamSection__slider'>
                            <Swiper
                                ref={sliderRef}
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                loop={true}
                                // loop={sliderArr1?.length > 4}
                                loopedSlides={2}
                                slidesPerView={currentMaxWidth >= 1000 ? 3 : 'auto'}
                                spaceBetween={checkWidth()}
                                speed={1000}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                onSlideChange={handleSlideChange}
                                pagination={false}
                                modules={[EffectCoverflow, Pagination]}
                                className="mySwiper"
                                allowTouchMove={currentMaxWidth > 1080}
                            // initialSlide={sliderArr1?.length < 5 && 1}
                            >
                                {sliderArr1.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img className='image' style={{ borderRadius: "15px" }} src={`${process.env.REACT_APP_IMG_URL}/${image.fileName}`} />
                                        <div className='activeSlide' onClick={() => { window.open(image.twitterLink, '_blank', 'noreferrer') }}>
                                            <img src={twitIcon} />
                                            <span>{image.name}</span>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        :
                        <div className='teamSection__teamItemWrap'>
                            {sliderArr1?.map((item, key) => {
                                return (
                                    <div key={key} className='teamSection__teamItemWrap-item'>
                                        <img className='image' src={item.img} />
                                        <div className='activeSlide'>
                                            <img src={twitIcon} />
                                            <span>{item.name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {sliderArr1?.length >= 3 &&
                        <div className='teamSection__btnWrap'>
                            <img className={`teamSection__btnWrap-img ${animatedItem === 'arrowL' ? 'animate' : ''} `} onClick={() => handlePrev('arrowL')} src={arrowL} />
                            <img className={`teamSection__btnWrap-img ${animatedItem === 'arrowR' ? 'animate' : ''} `} onClick={() => handleNext('arrowR')} src={arrowR} />
                        </div>
                    }
                </div>
            </div>
        </>

    )
}

export default TeamSection;
