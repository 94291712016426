import React from "react";
import "./style.scss";
import firstSectionBG from "../../../../images/mainPage/firstSectionBG.webp";
import heroBG from "../../../../images/mainPage/heroBG.png";
import Button from "../../../../components/button/button";
import SugarButton from "../../../../components/sugarHeadButton/sugarHeadButton";

const FirstSection = ({ menuOpen }) => {
  return (
    <div className="firstSectionWrapper">
      <img className="image" src={firstSectionBG} />
      <div className="gradient" />
      <div className={`gradientMax ${menuOpen ? "gradientActive" : ""}`} />
      <div className="first">
        <div className="firstSection limitWidth">
          <div className="firstSection__title">
            The Worlds First NFT Backed by a Basket of Digital Assets
          </div>
          <div className="firstSection__description">
            Every second sweetens the vault and every NFT gives you more. Secure
            your Sugar Heads NFT on OpenSea and anticipate the sugar rush when
            the vault pays its bounty!
          </div>
          <SugarButton text="Get Sugar heads Now" />
          {/* <Button text='Get Sugar heads Now' onClick={()=>{window.open('https://opensea.io/collection/sugarheadsnft', '_blank', 'noreferrer');}} /> */}
        </div>
      </div>
      <img className="bottomGradient" src={heroBG} />
    </div>
  );
};

export default FirstSection;
