// addressSlice.js
import { createSlice } from '@reduxjs/toolkit';

const contractSlice = createSlice({
  name: 'contract',
  initialState: '',
  reducers: {
    setContract: (state, action) => {
      return action.payload; // action.payload should contain the new address
    },
  },
});

export const { setContracts } = contractSlice.actions;
export const selectContract = (state) => state.address; // Selector to access the address in your Redux store

export default contractSlice.reducer;