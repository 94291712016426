import React, { useEffect, useRef, useState } from "react";
import './style.scss';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import TimeButtons from "../../../../components/timeButtons/timeButtons";
import PieChartComponent from "../pieChartComponent/pieChartComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arrow2 from "../../../../images/Arrow2.svg";
import Arrow1 from "../../../../images/Arrow1.svg";
import dopBG768 from "../../../../images/analyticsPage/dopBG768.png";
import CustomTooltip from "../../../../components/customTooltip/customTooltip";
import { getMonthlyGraph, getHourlyGraph, getWeeklyGraph, getYearlyGraph, getMaxGraph, getContract, wallectCurrencies, PieGraphValues, contractTime } from '../../../../store/UserSlices/UserSlices.js';
import { setContractAddress } from '../../../../store/UserSlices/ContractSlices';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import momentz from 'moment-timezone';

function calculateMaxWidth(viewportWidth) {
    if (viewportWidth >= 1440 && viewportWidth <= 1920) {
        const minWidthValue = 1360;
        const maxWidthValue = 1680;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1440) / (1920 - 1440));
    } else if (viewportWidth >= 1080 && viewportWidth <= 1440) {
        const minWidthValue = 1015;
        const maxWidthValue = 1360;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1080) / (1440 - 1080));
    } else if (viewportWidth >= 768 && viewportWidth <= 1080) {
        const minWidthValue = 716;
        const maxWidthValue = 1015;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 768) / (1080 - 768));
    } else if (viewportWidth >= 480 && viewportWidth <= 768) {
        const minWidthValue = 440;
        const maxWidthValue = 716;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 480) / (768 - 480));
    } else if (viewportWidth >= 320 && viewportWidth <= 480) {
        const minWidthValue = 280;
        const maxWidthValue = 440;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 320) / (480 - 320));
    } else if (viewportWidth > 1920) {
        return 1680
    }
    return null;
}
function calculateMaxHeight(innerWidth) {
    if (innerWidth >= 1440 && innerWidth <= 1920) {
        const minWidthValue = 456;
        const maxWidthValue = 535;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((innerWidth - 1440) / (1920 - 1440));
    } else if (innerWidth >= 1080 && innerWidth <= 1440) {
        return 333
    } else if (innerWidth >= 768 && innerWidth <= 1080) {
        const minWidthValue = 350;
        const maxWidthValue = 333;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((innerWidth - 768) / (1080 - 768));
    } else if (innerWidth >= 480 && innerWidth <= 768) {
        const minWidthValue = 335;
        const maxWidthValue = 350;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((innerWidth - 480) / (768 - 480));
    }else if (innerWidth >= 320 && innerWidth <= 480) {
        const minWidthValue = 200;
        const maxWidthValue = 335;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((innerWidth - 320) / (480 - 320));
    }else if (innerWidth > 1920) {
        return 535
    } else if (innerWidth <= 1079) {
        return 333;
    }
    return null;
}
function calcMarginX(viewportWidth) {
    if (viewportWidth >= 1440 && viewportWidth <= 1920) {
        const minWidthValue = 22;
        const maxWidthValue = 22;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1440) / (1920 - 1440));
    } else if (viewportWidth >= 1080 && viewportWidth <= 1440) {
        const minWidthValue = 16;
        const maxWidthValue = 22;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1080) / (1440 - 1080));
    } else if (viewportWidth >= 768 && viewportWidth <= 1080) {
        const minWidthValue = 16;
        const maxWidthValue = 16;
        return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 768) / (1080 - 768));
    } else if (viewportWidth >= 480 && viewportWidth < 768) {
        return 16
    } else if (viewportWidth >= 320 && viewportWidth < 480) {
        return 16
    } else if (viewportWidth > 1920) {
        return 22
    }
}
function calcMarginY(viewportWidth) {
    if (viewportWidth >= 1080 && viewportWidth <= 1920) {
        return 26;
    } else if (viewportWidth >= 768 && viewportWidth < 1080) {
        return 20;
    } else if (viewportWidth >= 320 && viewportWidth <= 768) {
        return 10;
    } else if (viewportWidth > 1920) {
        return 26;
    }
}

const VoltSection = ({ walletConnect, menuOpen }) => {
    const dispatch = useDispatch();
    const [animatedItem, setAnimatedItem] = useState(null);
    const [timeInterval, setTimeInterval] = useState('24h');
    const [filteredData, setFilteredData] = useState([]);
    const [contract, setContract] = useState(null);
    const [slickArr, setSlickArr] = useState(null);
    const [activeButton, setActiveButton] = useState(1);
    const [array, setArray] = useState(null);
    const [loctime, setLocTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [graphAnimation, setGraphAnimation] = useState('fade-in');
    const [xtickArray, setTickArray] = useState(null)
    const [apiDate, setApiDate] = useState(null)




    useEffect(() => {
        dispatch(getContract()).then((res) => {
            const contractDocs = res?.payload?.result?.contract?.docs;
            if (contractDocs) {
                setContract(contractDocs);
                setAddress(contractDocs[0]?.address);
                dispatch(setContractAddress(contractDocs[0]))
                if (contractDocs.length > 0) {
                    dispatch(getHourlyGraph(contractDocs[0]?.address)).then((res) => {
                        setFilteredData(res?.payload?.result?.values[0].perHour);
                        setApiDate(res?.payload?.result?.values[0].date);
                        setVaultValue(res?.payload?.result?.values[0]?.avgVaultValue);
                        setAveragePrice(res?.payload?.result?.values[0]?.avgFloorPrice);
                        setHighestPrice(res?.payload?.result?.values[0]?.avgHighestPrice);
                    });
                    dispatch(wallectCurrencies(contractDocs[0]?.address)).then((res) => {
                        let arr = res?.payload?.result?.tokens?.filter((res)=>{
                            if(res?.quote_rate != null && res?.quote_rate != '0.00'){
                                return res;
                            }
                        })
                        setSlickArr(arr);

                    })
                    dispatch(PieGraphValues(contractDocs[0]?.address)).then((res) => {

                        // const newArray = res?.payload?.result?.tokens?.slice(0, 5);
                        // const combinedBalance = res?.payload?.result?.tokens?.slice(5).reduce((total, item) => total + item.balance, 0);
                        // newArray.push({
                        //     "contract_decimals": 0,
                        //     "contract_ticker_symbol": "others",
                        //     "balance": Number(combinedBalance)
                        // });
                        setArray(res?.payload?.result?.tokens)
                    })
                    dispatch(contractTime(contractDocs[0]?._id)).then((res) => {
                        setLocTime(res?.payload?.result?.lockTime)
                    })
                }
            }
        });
    }, [dispatch]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const timeIntervals = ['24h', '7d', '30d', '1y', 'Max']
    const sliderRef = useRef(null);
    const currentWidth = window.innerWidth;
    const currentMaxWidth = calculateMaxWidth(window.innerWidth);
    const currentMaxHeight = calculateMaxHeight(window.innerWidth);
    const currentMarginX = calcMarginX(window.innerWidth);
    const currentMarginY = calcMarginY(window.innerWidth);
    const isLastSlide = currentSlide === slickArr?.length - 6;
    const isFirstSlide = currentSlide === 0;
    const [address, setAddress] = useState(null);
    const [vaultValue, setVaultValue] = useState(null);
    const [averagePrice, setAveragePrice] = useState(null);
    const [highestPrice, setHighestPrice] = useState(null);
    const handleTap = (item) => {
        setAnimatedItem(item);
        setTimeout(() => {
            setAnimatedItem(null);
        }, 500);
    };
    const handlePrev = (item) => {
        handleTap(item);
        if (!isFirstSlide && sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };
    const handleNext = (item) => {
        handleTap(item);
        if (currentSlide < slickArr?.length - 6) {
            if (sliderRef.current) {
                sliderRef.current.slickNext();
            }
        }
    };
    const handleTimeIntervalChange = (interval) => {

        if (interval === '7d') {
            dispatch(getWeeklyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);

            });
        }
        if (interval === '30d') {
            dispatch(getMonthlyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);
            });
        }
        if (interval === '24h') {
            dispatch(getHourlyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values[0].perHour);
                setApiDate(res?.payload?.result?.values[0].date); 

            });
        }
        if (interval === '1y') {
            dispatch(getYearlyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);
            });
        }
        if (interval === 'Max') {
            dispatch(getMaxGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);
            });
        }
        setTimeInterval(interval);
    }

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        speed: 300,
        vertical: true,
        verticalSwiping: currentWidth > 1080,
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        if (date == 'Invalid Date') {

            var times = moment(dateString, "HH:mm");
            
            var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')

            var _isoSplit = formattedTime?.split('-')
            var _isoSplit2 = _isoSplit[_isoSplit?.length -1]?.split('T')
            var isoSplit = [..._isoSplit, ..._isoSplit2]
           
            let dateDay
            if(apiDate?.split('/')[1].length < 2){
                dateDay = `0${apiDate?.split('/')[1]}`
            }
            else{
                dateDay = apiDate?.split('/')[1]
            }
            
            let dateMonth
            if(apiDate?.split('/')[0].length < 2){
                dateMonth = `0${apiDate?.split('/')[0]}`
            }
            else{
                dateMonth = apiDate?.split('/')[1]
            }
            
            var finalDate = `${apiDate?.split('/')[2]}-${dateMonth}-${dateDay}T${isoSplit[isoSplit?.length -1]}`

            // 2023-11-29T21:00:00Z
            var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const localTime = moment.utc(finalDate).tz(localZone);
            return localTime.format('h:mm A')
        }
        else {
            const currentUTCTime = moment.utc();
            const hours = currentUTCTime.format('HH');
            const minutes = currentUTCTime.format('mm');
            const seconds = currentUTCTime.format('ss');

            var times = moment(dateString, "MM/DD/YYYY");
            times.set({ hour: hours, minute: minutes, second: seconds });
            
            var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')
           
            var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const localTime = moment.utc(formattedTime).tz(localZone);
            return localTime.format('L')
        }
    }
    const handleHour = (time) => {
        var times = moment(time, "HH:mm");
            
        var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')
      
        var _isoSplit = formattedTime.split('-')
     
        var _isoSplit2 = _isoSplit[_isoSplit?.length -1]?.split('T')
        var isoSplit = [..._isoSplit, ..._isoSplit2]

        let dateDay
        if(apiDate?.split('/')[1].length < 2){
            dateDay = `0${apiDate?.split('/')[1]}`
        }
        else{
            dateDay = apiDate?.split('/')[1]
        }

        let dateMonth
        if(apiDate?.split('/')[0].length < 2){
            dateMonth = `0${apiDate?.split('/')[0]}`
        }
        else{
            dateMonth = apiDate?.split('/')[1]
        }
        
        var finalDate = `${apiDate?.split('/')[2]}-${dateMonth}-${dateDay}T${isoSplit[isoSplit?.length -1]}`
        // 2023-11-29T21:00:00Z
        var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const localTime = momentz.utc(finalDate).tz(localZone);
        return localTime.format('HH:mm')
    }

    const handleMonthDays = (date) => {
        const currentUTCTime = moment.utc();
            const hours = currentUTCTime.format('HH');
            const minutes = currentUTCTime.format('mm');
            const seconds = currentUTCTime.format('ss');

            var times = moment(date, "MM/DD/YYYY");
            times.set({ hour: hours, minute: minutes, second: seconds });
            
            var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')
           
            var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const localTime = moment.utc(formattedTime).tz(localZone);
            return localTime.format('DD MMM')
    }

    const handleButtonClick = (buttonNumber) => {
        setGraphAnimation('fade-out');
        setTimeout(() => {
            setActiveButton(buttonNumber);
            setGraphAnimation('fade-in');
        }, 500);
    };

    const handleContract = (value, address) => {
        dispatch(getHourlyGraph(value.address)).then((res) => {
            setFilteredData(res?.payload?.result?.values[0].perHour);
            setApiDate(res?.payload?.result?.values[0].date);
            setVaultValue(res?.payload?.result?.values[0]?.avgVaultValue);
            setAveragePrice(res?.payload?.result?.values[0]?.avgFloorPrice);
            setHighestPrice(res?.payload?.result?.values[0]?.avgHighestPrice);
        });
        setAddress(value.address);
        dispatch(setContractAddress(value))
        setTimeInterval('24h');
        dispatch(PieGraphValues(value.address)).then((res) => {
            // const piegraph = res?.payload?.result?.tokens.sort((a, b) => {
            //     return b.balance - a.balance;
            // });
            // const newArray = piegraph?.slice(0, 5);
            // const combinedBalance = piegraph?.slice(5).reduce((total, item) => total + item.balance, 0);
            // newArray.push({
            //     "contract_decimals": 0, // You can adjust this as needed
            //     "contract_ticker_symbol": "others",
            //     "balance": combinedBalance
            // });
            setArray(res?.payload?.result?.tokens)
        })
        dispatch(contractTime(address?._id)).then((res) => {
            setLocTime(res?.payload?.result?.lockTime);

        })
    }

    return (
        <div className='voltSectionWrapper'>
            <div className='voltSectionWrapper__gradient' />
            <div className={`voltSectionWrapper__gradientMax ${menuOpen ? 'gradientActive' : ''}`} />
            <div className='voltSection limitWidth'>
                <div className='voltSection__firstBlock'>
                    <div className='voltSection__firstBlock-controls'>
                        <div style={{ display: 'flex' }}>
                            {
                                contract ? contract.map((item, index) => {
                                    return (<div key={index}>
                                        <div
                                            style={{ margin: '10px' }}
                                            className={`voltSection__firstBlock-controls-indicator ${activeButton === index + 1 ? 'active' : 'inactive'} ${animatedItem === index + 1 ? 'animate' : ''}`}
                                            onClick={() => {
                                                handleTap(index + 1);
                                                handleButtonClick(index + 1)
                                                handleContract(item, contract[index])
                                            }}
                                        >
                                            Vault 0{index + 1}
                                        </div>
                                    </div>
                                    )
                                }) : ""
                            }
                        </div>
                        <TimeButtons
                            timeIntervals={timeIntervals}
                            activeInterval={timeInterval}
                            onIntervalChange={handleTimeIntervalChange}
                            page='analytics'
                        />
                    </div>
                    <div className='voltSection__firstBlock-graph'>
                        <LineChart
                            width={currentMaxWidth}
                            height={currentMaxHeight}
                            data={filteredData}
                            key={timeInterval}
                            margin={{ top: 0, right: 0, left: currentWidth < 1080 ? -15 : 0, bottom: 0 }}
                        >
                            <CartesianGrid stroke="#000" strokeDasharray="3 3" />
                            <XAxis
                                dataKey={timeInterval === '24h' ? "time" : timeInterval === '7d' ? "date" : timeInterval === '30d' ? "date" : timeInterval === '1y' ? "date" : timeInterval === 'Max' ? "date" : ""}
                                tickMargin={12}
                                interval={timeInterval === '30d' ? 10 : timeInterval === '1y' ? 120 : timeInterval === 'Max' ? 241 : timeInterval === '7d' ? 2 : timeInterval === '24h' ? 7 : 0}
                                tick={(props) => {
                                    const formattedValue = props.tickFormatter(props.payload.value);
                                    const xShift = props.index === 0 ? 30 : timeInterval === '1y' ? -40 : timeInterval === 'Max' ? -40 : -25;
                                    if (window.innerWidth < 450 && window.innerWidth > 410) {
                                        if (props.index !== 0 && props.index !== 1 && props.index !== 3) {
                                            return null;
                                        }
                                    }
                                    else if (window.innerWidth < 409) {
                                        if (props.index !== 0 && props.index !== 3) {
                                            return null;
                                        }
                                    }
                                    return (
                                        <g>
                                            <text
                                                x={props.x + xShift}
                                                y={props.y}
                                                dy={props.dy}
                                                fontSize={window.innerWidth < 1080 ? '10px' : '16px'}
                                                fontFamily="Russo One"
                                                fontWeight="400"
                                                textAnchor={props.textAnchor}
                                                fill="rgba(0, 0, 0, 0.50)"
                                                padding={'15px'}
                                            >

                                                {/* {moment(props.payload.value).format()} */}
                                                {formattedValue}
                                            </text>
                                        </g>
                                    );
                                }
                                }
                                // domain={currentDomain}
                                tickValues={xtickArray}
                                tickFormatter={date => {
                                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                    const d = new Date(date);

                                    const hours = String(d.getHours()).padStart(2, '0');
                                    const minutes = String(d.getMinutes()).padStart(2, '0');
                                    if (timeInterval === '30d' || timeInterval === '7d' || timeInterval === '24h') {
                                        return timeInterval === '24h' ? handleHour(date) : handleMonthDays(date);
                                    } else {
                                        return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
                                    }
                                }}
                            />
                            <YAxis
                                tickLine={false}
                                tickMargin={currentMarginY}
                                width={90}
                                tick={{
                                    fill: 'rgba(0, 0, 0, 0.50)',
                                    fontSize: window.innerWidth < 1080 ? '10px' : '16px',
                                    fontFamily: 'Russo One',
                                    fontWeight: '400',
                                    lineHeight: window.innerWidth < 1080 ? '14px' : '24px'
                                }}

                            />

                            <Tooltip content={(props) => <CustomTooltip {...props} formatDate={formatDate} />} />
                            {/* <Line type="monotone" dataKey={timeInterval === '24h' ? "floorPrice" : timeInterval === '7d' ? 'avgHighestPrice' : timeInterval === '30d' ? "avgHighestPrice" : timeInterval === '1y' ? "avgHighestPrice" : timeInterval === 'Max' ? "avgHighestPrice" : ""} strokeWidth={1.6} stroke="#FFF" dot={false} activeDot={false} /> */}
                            <Line type="monotone" dataKey={timeInterval === '24h' ? "floorPrice" : timeInterval === '7d' ? 'avgFloorPrice' : timeInterval === '30d' ? "avgFloorPrice" : timeInterval === '1y' ? "avgFloorPrice" : timeInterval === 'Max' ? "avgFloorPrice" : ""} strokeWidth={1.6} stroke="#87FCB6" dot={false} activeDot={false} />
                            <Line type="monotone" dataKey={timeInterval === '24h' ? "highestPrice" : timeInterval === '7d' ? "avgHighestPrice" : timeInterval === '30d' ? "avgHighestPrice" : timeInterval === '1y' ? "avgHighestPrice" : timeInterval === 'Max' ? "avgHighestPrice" : ""} strokeWidth={1.6} stroke="#ffffff" dot={false} activeDot={false} />
                           
                            {/* <Line type="monotone" dataKey={timeInterval === '24h' ? "highestPrice" : timeInterval === '7d' ? "avgFloorPrice" : timeInterval === '30d' ? "avgFloorPrice" : timeInterval === '1y' ? "avgFloorPrice" : timeInterval === 'Max' ? "avgFloorPrice" : ""} strokeWidth={1.6} stroke="#EE9DD6" dot={false} activeDot={false} /> */}
                        </LineChart>
                    </div>
                    <div className='voltSection__firstBlock-info'>
                        <div className='voltSection__firstBlock-info-item'>
                            <div className='voltSection__firstBlock-info-item-wrap'>
                                Vault Value:&nbsp; <span>${vaultValue ? vaultValue : "0"}</span>
                            </div>
                        </div>
                        <div className='voltSection__firstBlock-info-item'>
                            <div className='voltSection__firstBlock-info-item-wrap'>
                                Backing Value:&nbsp; <span>${highestPrice ? highestPrice : "0"}</span>
                            </div>
                        </div>
                        <div className='voltSection__firstBlock-info-item'>
                            <div className='voltSection__firstBlock-info-item-wrap'>
                                Floor Price:&nbsp;  <span>${averagePrice ? averagePrice : "0"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='voltSection__dopBG'>
                    <img src={dopBG768} alt='dopBG768' />
                </div>
                <div className='voltSection__lastBlock'>
                    <div className='voltSection__lastBlock-listWrap'>
                        <div className='voltSection__lastBlock-listWrap-list'>
                            <Slider ref={sliderRef} {...settings} afterChange={current => setCurrentSlide(current)}>
                                {slickArr?.map((item, key) => {
                                    return (
                                        <div key={key} className='voltSection__lastBlock-listWrap-list-item'  >
                                            <div className='voltSection__lastBlock-listWrap-list-item-title' style={{ fontSize: '15px' }}>
                                                {item?.contract_ticker_symbol}
                                            </div>
                                            <div className='voltSection__lastBlock-listWrap-list-item-wrap' >
                                                <div className='voltSection__lastBlock-listWrap-list-item-wrap-value' style={{ fontSize: "15px" }}>
                                                    Amount: &nbsp;<span style={{ fontSize: "15px" }} >{item?.balance}</span>
                                                </div>
                                                <div className='voltSection__lastBlock-listWrap-list-item-wrap-value sec' style={{ fontSize: "15px" }}>
                                                    Market price: &nbsp;
                                                        <span style={{ fontSize: "15px" }} >${item?.quote_rate ? (item?.quote_rate) : ` -`}</span>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                            <div className='voltSection__lastBlock-listWrap-list-controls'>
                                <div className={`voltSection__lastBlock-listWrap-list-controls-btn ${animatedItem === 'btn1' ? 'animate' : ''} ${isFirstSlide ? 'disabled-btn' : ''}`} onClick={() => handlePrev('btn1')}>
                                    <img src={Arrow2} />
                                </div>
                                <div className={`voltSection__lastBlock-listWrap-list-controls-btn last ${animatedItem === 'btn2' ? 'animate' : ''} ${isLastSlide ? 'disabled-btn' : ''}`} onClick={() => handleNext('btn2')}>
                                    <img src={currentWidth <= 768 ? Arrow2 : Arrow1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='voltSection__lastBlock-pieChartWrap'>
                        <PieChartComponent array={array} walletConnect={walletConnect} currentWidth={currentWidth} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VoltSection;