import React, {useEffect, useState} from "react";
import logo from '../../images/SH_LOGO1.png';
import './style.scss';
import openSeaIcon from '../../images/openSeaIcon.svg';
import twitterIcon from '../../images/twitterIcon.svg';
import discordIcon from '../../images/discordIcon.svg';
import Button from "../button/button";
import {scroller} from 'react-scroll';
import {Link as NavLink, redirect, useNavigate} from 'react-router-dom';
import showMenu from '../../images/showMenuIcon.png';
import hideMenu from '../../images/hideMenuIcon.png';
import ScrollDisabler from "../../utils/scrollDisabler";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const Header = ({activePage, setActivePage, setMenuOpen, menuOpen, setWalletConnect}) => {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [animatedItem, setAnimatedItem] = useState(null);
    // const history = useHistory();
    const toggleMenu = () => {
        if (!menuOpen) {
            ScrollDisabler.disable();
        } else {
            ScrollDisabler.enable();
        }
        setMenuOpen(!menuOpen);
    };
    const handleTap = (item, path) => {
        setAnimatedItem(item);
        setTimeout(() => {
            setAnimatedItem(null);
            setMenuOpen(false);
            ScrollDisabler.enable();
            if (path) {
                navigate(path);
            }
        }, 500);
    };
    const navigate = useNavigate();

    const handleNavLinkClick = (path, sectionName) => {
        if (path === window.location.pathname) {
            scroller.scrollTo(sectionName, {
                duration: 500,
                delay: 0,
                smooth: true,
                offset: sectionName === 'cookingSection' ? -200 : -100
            });
        } else {
            setActivePage('main');
            navigate(path, { state: { scrollTo: sectionName } });
        }
    }

    const handleRouting=(value)=>{
        // redirect(value)
        window.location.replace(value);
    }

    return (
        <div className='headerWrapper'>
            <div className='header limitWidth'>
                <div className='header__firstSection'>
                    <div className='header__firstSection-logo' onClick={() => setActivePage('main')}>
                        <NavLink to='/'>
                            <img src={logo} />
                        </NavLink>
                    </div>
                    <div className='header__firstSection-items'>
                        <div className={`header__firstSection-items-item ${activePage === 'main' ? 'active' : ''}`}>
                            <div onClick={() => handleNavLinkClick('/', 'cookingSection')}>
                                What's CARAMELIZING?
                            </div>
                        </div>
                        <div className='header__firstSection-items-item'>
                            <div onClick={() => handleNavLinkClick('/', 'vaultSection')}>
                                Vault
                            </div>
                        </div>
                        <div className='header__firstSection-items-item'>
                            <div onClick={() => handleNavLinkClick('/', 'teamSection')}>
                                Team
                            </div>
                        </div>

                        <div
                            onClick={() => setActivePage('rewards')}
                            className={`header__firstSection-items-item ${activePage === 'rewards' ? 'active' : ''}`}
                        >
                            <NavLink to='/analytics'>
                                Rewards & Analytics
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='header__lastSection'>
                    <div className='header__lastSection-socialButtons'>
                        <div className='header__lastSection-socialButtons-item'>
                           <a href="https://opensea.io/collection/sugarheadsnft" target="_blank"> <img src={openSeaIcon}  /> </a>
                        </div>
                        <div className='header__lastSection-socialButtons-item'>
                           <a href='https://twitter.com/SugarHeads_NFT' target="_blank"> <img src={twitterIcon}/>  </a>
                        </div>
                        <div className='header__lastSection-socialButtons-item'>
                           <a href="https://discord.com/invite/ZbQbQrar8g" target="_blank"> <img src={discordIcon}/> </a>
                        </div>
                    </div>
                    <Button  text={isConnected ? (address.slice(0, 6) + '...' + address.slice(-4))  :'Connect Wallet'} />
                </div>
                <div className='header__mobBtn' onClick={toggleMenu}>
                    <img src={menuOpen ? hideMenu : showMenu} />
                </div>
            </div>
            <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
                <div className='mobile-menu__titleWrap'>
                    <div onClick={() => handleTap('main', '/')} className={`mobile-menu__titleWrap-title ${animatedItem === 'main' ? 'animate' : ''}`}>
                        Main
                    </div>
                    <div onClick={() => handleTap('analytics', '/analytics')} className={`mobile-menu__titleWrap-title ${animatedItem === 'analytics' ? 'animate' : ''}`}>
                        Rewards & Analytics
                    </div>
                </div>
                <Button text='connect wallet' />
                <div className='mobile-menu__text'>
                    CHECK OUR SOCIALS:
                </div>
                <div className='mobile-menu__btns'>
                    <div
                        className={`mobile-menu__btns-item ${animatedItem === 'openSea' ? 'animate' : ''}`}
                        onTouchEnd={() => handleTap('openSea')}
                    >
                        <img src={openSeaIcon}/>
                    </div>
                    <div
                        className={`mobile-menu__btns-item ${animatedItem === 'twitter' ? 'animate' : ''}`}
                        onTouchEnd={() => handleTap('twitter')}
                    >
                        <img src={twitterIcon}/>
                    </div>
                    <div
                        className={`mobile-menu__btns-item ${animatedItem === 'discord' ? 'animate' : ''}`}
                        onTouchEnd={() => handleTap('discord')}
                    >
                        <img src={discordIcon}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
