import React, {useState} from "react";
import './style.scss';
import openSeaIcon from "../../images/openSeaIcon.svg";
import twitterIcon from "../../images/twitterIcon.svg";
import discordIcon from "../../images/discordIcon.svg";
import logo from "../../images/SH_LOGO1.png";
import Button from "../button/button";
import {Link as NavLink, useNavigate} from "react-router-dom";

const Footer = ({setActivePage}) => {
    const [animatedItem, setAnimatedItem] = useState(null);
    const navigate = useNavigate();
    const handleLogoClick = () => {
        window.scrollTo(0, 0);
        setActivePage('main');
    }

    const handleTap = (item, path) => {
        setAnimatedItem(item);
        setTimeout(() => {
            setAnimatedItem(null);
            if (path) {
                navigate(path);
            }
        }, 500);
    };

    return (
        <div className='footerWrapper'>
            <div className='gradientSec' />
            <div className='footer limitWidth'>
                <div className='footer__upper'>
                    <div className='footer__upper-title'>
                        Ready To Get Started?
                    </div>
                    <Button text='Get Started' />
                </div>
                <div className='footer__first'>
                    <div className='footer__first-logo' onClick={handleLogoClick}>
                        <NavLink to='/' >
                            <img className='footer__first-logo-image' src={logo}/>
                        </NavLink>
                    </div>
                    <div
                        className={`footer__first-text ${animatedItem === 'analytics' ? 'animate' : ''}`}
                        onClick={() => handleTap('analytics', '/analytics')}
                    >
                        Rewards & Analytics
                    </div>
                    <div className='footer__first-btnWrapper'>
                        <div
                            className={`footer__first-btnWrapper-item ${animatedItem === 'openSea' ? 'animate' : ''}`}
                            onClick={() => handleTap('openSea')}
                        >
                            <a href="https://opensea.io/collection/sugarheadsnft" target="_blank"> <img src={openSeaIcon}  /> </a>
                        </div>
                        <div
                            className={`footer__first-btnWrapper-item ${animatedItem === 'twitter' ? 'animate' : ''}`}
                            onClick={() => handleTap('twitter')}
                        >
                            <a href='https://twitter.com/SugarHeads_NFT' target="_blank"> <img src={twitterIcon}/>  </a>
                        </div>
                        <div
                            className={`footer__first-btnWrapper-item ${animatedItem === 'discord' ? 'animate' : ''}`}
                            onClick={() => handleTap('discord')}
                        >
                            <a href="https://discord.com/invite/ZbQbQrar8g" target="_blank"> <img src={discordIcon}/> </a>
                        </div>
                    </div>
                </div>
                <div className='footer__last'>
                    <div
                        className={`footer__last-item ${animatedItem === 'terms' ? 'animate' : ''}`}
                        onClick={() => handleTap('terms')}
                    >
                        Terms & Conditions
                    </div>
                    <div
                        className={`footer__last-item ${animatedItem === 'privacy' ? 'animate' : ''}`}
                        onClick={() => handleTap('privacy')}
                    >
                        Privacy Policy
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
