import React from "react";
import './style.scss';
import moment from 'moment'

function CustomTooltip({ payload, label, active, formatDate }) {
    if (active) {
        return (
            <div className='wrapper'>
                <div className='wrapper__title'>
                    {formatDate(label)}
                </div>
                <div className='wrapper__infoValue'>
                    <div className='wrapper__infoValue-first'>
                        Floor Price:&nbsp; <span>${payload && payload.length > 0 ? payload[0].value : null}</span>
                    </div>
                    <div className='wrapper__infoValue-second'>
                        Backing Value:&nbsp; <span>${payload && payload.length > 0 ? payload[1].value : null}</span>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}

export default CustomTooltip;
