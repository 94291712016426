// addressSlice.js
import { createSlice } from '@reduxjs/toolkit';

const addressSlice = createSlice({
  name: 'address',
  initialState: '',
  reducers: {
    setContractAddress: (state, action) => {
      return action.payload; // action.payload should contain the new address
    },
  },
});

export const { setContractAddress } = addressSlice.actions;
export const selectAddress = (state) => state.address; // Selector to access the address in your Redux store

export default addressSlice.reducer;
