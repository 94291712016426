import React, { useEffect, useState } from "react";
import './style.scss';
import lightningIcon from '../../../../images/mainPage/lightningIcon.png'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import TimeButtons from "../../../../components/timeButtons/timeButtons";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../../components/customTooltip/customTooltip";
import { getMonthlyGraph,
    getHourlyGraph,
    getWeeklyGraph,
    getYearlyGraph,
    getMaxGraph,
    getContract,
    contractTime } from '../../../../store/UserSlices/UserSlices.js';
import { useDispatch, useSelector } from "react-redux";

import { setContractAddress } from '../../../../store/UserSlices/ContractSlices';
// import {setContracts} from "../../../../store/UserSlices/AddressSlices.js"
import moment from 'moment'


const VaultSection = () => {
    const dispatch = useDispatch();
    const [animatedItem, setAnimatedItem] = useState(null);
    const [activeButton, setActiveButton] = useState(1);
    const [timeInterval, setTimeInterval] = useState('24h');
    const timeIntervals = ['24h', '7d', '30d', '1y', 'Max'];
    const [time, setTime] = useState({
        days: 23,
        hours: 19,
        minutes: 1
    });
    const [graphAnimation, setGraphAnimation] = useState('fade-in');
    const [filteredData, setFilteredData] = useState([]);
    const [currentDomain, setCurrentDomain] = useState([]);

    const [loctime, setLocTime] = useState(null);
    const navigate = useNavigate();
    const [contract, setContract] = useState(null);
    const [address, setAddress] = useState(null);
    const [vaultValue, setVaultValue] = useState(null);
    const [averagePrice, setAveragePrice] = useState(null);
    const [highestPrice, setHighestPrice] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [countdown, setCountdown] = useState({});
    const [xtickArray,setTickArray] = useState(null)
    const [apiDate, setApiDate] = useState(null)
    
    
    function calculateRemainingTime(lockTime) {
        const now = moment();
        const time = moment(lockTime * 1000);

        
        const duration = moment.duration(time?.diff(now));
        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.asHours()) % 24;
        const minutes = Math.floor(duration.asMinutes()) % 60;
        setCountdown({ days,hours, minutes });
        return {days, hours, minutes };
    }




    useEffect(() => {
        dispatch(getContract()).then((res) => {
            const contractDocs = res?.payload?.result?.contract?.docs;
            if (contractDocs) {
                
                setContract(contractDocs);
                setAddress(contractDocs[0]?.address)
                dispatch(setContractAddress(contractDocs[0]))
                if (contractDocs) {
                    dispatch(getHourlyGraph(contractDocs[0]?.address)).then((res) => {
                        setApiDate(res?.payload?.result?.values[0].date);
                        setFilteredData(res?.payload?.result?.values[0].perHour);
                        setVaultValue(res?.payload?.result?.values[0]?.avgVaultValue);
                        setAveragePrice(res?.payload?.result?.values[0]?.avgFloorPrice);
                        setHighestPrice(res?.payload?.result?.values[0]?.avgHighestPrice);
                        
                    });
                    dispatch(contractTime(contractDocs[0]?._id)).then((res) => {
                        setLocTime(res?.payload?.result?.lockTime)
                    })
                }

            }
        });

      
    }, [dispatch]);

    const handleTap = (item, path) => {
        setAnimatedItem(item);
        setTimeout(() => {
            setAnimatedItem(null);
            if (path) {
                navigate(path);
            }
        }, 500);
    };

    const currentMaxWidth = calculateMaxWidth(window.innerWidth);
    const currentMaxHeight = calculateMaxHeight(window.innerWidth);

    function calculateMaxWidth(viewportWidth) {
        if (viewportWidth >= 1440 && viewportWidth <= 1920) {
            const minWidthValue = 904;
            const maxWidthValue = 1200;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1440) / (1920 - 1440));
        } else if (viewportWidth >= 1080 && viewportWidth <= 1440) {
            const minWidthValue = 600;
            const maxWidthValue = 904;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1080) / (1440 - 1080));
        } else if (viewportWidth >= 480 && viewportWidth <= 768) {
            const minWidthValue = 405;
            const maxWidthValue = 633;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 480) / (768 - 480));
        } else if (viewportWidth >= 320 && viewportWidth <= 480) {
            const minWidthValue = 244;
            const maxWidthValue = 405;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 320) / (480 - 320));
        } else if (viewportWidth <= 1079) {
            return 633;
        } else if (viewportWidth > 1920) {
            return 1200
        }
        return null;
    }
    function calculateMaxHeight(innerWidth) {
        if (innerWidth >= 1440 && innerWidth <= 1920) {
            const minWidthValue = 333;
            const maxWidthValue = 440;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((innerWidth - 1440) / (1920 - 1440));
        } else if (innerWidth >= 1080 && innerWidth <= 1440) {
            return 333
        } else if (innerWidth >= 320 && innerWidth <= 480) {
            const minWidthValue = 200;
            const maxWidthValue = 333;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((innerWidth - 320) / (480 - 320));
        } else if (innerWidth > 1920) {
            return 440
        } else if (innerWidth <= 1079) {
            return 333;
        }
        return null;
    }


  

    const handleTimeIntervalChange = (interval) => {
       
        if (interval === '7d') {
            dispatch(getWeeklyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);
            });
        }
        if (interval === '30d') {
           
            dispatch(getMonthlyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);
                setTimeInterval('30d')
            });
        }
        if (interval === '24h') {
            dispatch(getHourlyGraph(address)).then((res) => {
                setApiDate(res?.payload?.result?.values[0].date);
                setFilteredData((res?.payload?.result?.values[0].perHour));
            });
        }
        if (interval === '1y') {
            dispatch(getYearlyGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values);
            });
        }
        if (interval === 'Max') {
            dispatch(getMaxGraph(address)).then((res) => {
                setFilteredData(res?.payload?.result?.values)
            });
        }
        setTimeInterval(interval);
    }
   
    const handleButtonClick = (buttonNumber) => {
        setGraphAnimation('fade-out');

        setTimeout(() => {
            setActiveButton(buttonNumber);
            setGraphAnimation('fade-in');
        }, 500);
    };

    

    function formatDate(dateString) {
        const date = new Date(dateString);
        if (date == 'Invalid Date') {
    
            var times = moment(dateString, "HH:mm");
            
            var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')

            var _isoSplit = formattedTime?.split('-')
            var _isoSplit2 = _isoSplit[_isoSplit?.length -1]?.split('T')
            var isoSplit = [..._isoSplit, ..._isoSplit2]
           
            let dateDay
            if(apiDate?.split('/')[1].length < 2){
                dateDay = `0${apiDate?.split('/')[1]}`
            }
            else{
                dateDay = apiDate?.split('/')[1]
            }
            
            let dateMonth
            if(apiDate?.split('/')[0].length < 2){
                dateMonth = `0${apiDate?.split('/')[0]}`
            }
            else{
                dateMonth = apiDate?.split('/')[1]
            }
            
            var finalDate = `${apiDate?.split('/')[2]}-${dateMonth}-${dateDay}T${isoSplit[isoSplit?.length -1]}`

            // 2023-11-29T21:00:00Z
            var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const localTime = moment.utc(finalDate).tz(localZone);
         
            return localTime.format('h:mm A')
        }
        else {
            const currentUTCTime = moment.utc();
            const hours = currentUTCTime.format('HH');
            const minutes = currentUTCTime.format('mm');
            const seconds = currentUTCTime.format('ss');

            var times = moment(dateString, "MM/DD/YYYY");
            times.set({ hour: hours, minute: minutes, second: seconds });
            
            var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')
           
            var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const localTime = moment.utc(formattedTime).tz(localZone);
            return localTime.format('L')
        }
    }

    const handleHour = (time) => {
        var times = moment(time, "HH:mm");
            
        var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')

        var _isoSplit = formattedTime?.split('-')
        var _isoSplit2 = _isoSplit[_isoSplit?.length -1]?.split('T')
        var isoSplit = [..._isoSplit, ..._isoSplit2]
        
        let dateDay
        if(apiDate?.split('/')[1].length < 2){
            dateDay = `0${apiDate?.split('/')[1]}`
        }
        else{
            dateDay = apiDate?.split('/')[1]
        }
        
        let dateMonth
        if(apiDate?.split('/')[0].length < 2){
            dateMonth = `0${apiDate?.split('/')[0]}`
        }
        else{
            dateMonth = apiDate?.split('/')[1]
        }
        
        var finalDate = `${apiDate?.split('/')[2]}-${dateMonth}-${dateDay}T${isoSplit[isoSplit?.length -1]}`

        // 2023-11-29T21:00:00Z
        var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const localTime = moment.utc(finalDate).tz(localZone);
        return localTime.format('HH:mm')
    }

    const handleMonthDays = (date) => {
        const currentUTCTime = moment.utc();
            const hours = currentUTCTime.format('HH');
            const minutes = currentUTCTime.format('mm');
            const seconds = currentUTCTime.format('ss');

            var times = moment(date, "MM/DD/YYYY");
            times.set({ hour: hours, minute: minutes, second: seconds });
            
            var formattedTime = times.format('YYYY-MM-DDTHH:mm:ss[Z]')
           
            var localZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const localTime = moment.utc(formattedTime).tz(localZone);
            return localTime.format('DD MMM')
    }

    const handleContract = (value, address) => {
        dispatch(getHourlyGraph(value.address)).then((res) => {
            setFilteredData(res?.payload?.result?.values[0].perHour);
            setApiDate(res?.payload?.result?.values[0].date);
            setVaultValue(res?.payload?.result?.values[0]?.avgVaultValue);
            setAveragePrice(res?.payload?.result?.values[0]?.avgFloorPrice);
            setHighestPrice(res?.payload?.result?.values[0]?.avgHighestPrice);
        });
        dispatch(contractTime(address?._id)).then((res) => {
            setLocTime(res?.payload?.result?.lockTime);
        })
        setAddress(value.address);
        dispatch(setContractAddress(value));
        // dispatch(setContracts(value?.address))
        setTimeInterval('24h');
    }

    useEffect(() => {
        // ... your existing code to set loctime ...
    
        // Call calculateRemainingTime immediately
        setRemainingTime(calculateRemainingTime(loctime));
    
        // Set up an interval to call calculateRemainingTime every second
        const intervalId = setInterval(() => {
            const newRemainingTime = calculateRemainingTime(loctime);
            setRemainingTime(newRemainingTime);
        }, 1000);
    
        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [loctime]);

   

    return (
        <div className='vaultSectionWrapper'>
            <div className='vaultSection limitWidth'>
                <div className='vaultSection__header'>
                    <div className='vaultSection__header-title'>
                        THE VAULT
                        <div className='vaultSection__header-title-imgWrapper'>
                            <img src={lightningIcon} />
                        </div>
                    </div>
                    <div className='vaultSection__header-description'>
                        Plunge into the heart of Sugar Heads’ treasury. Below we graphically chart the ever-changing value of our secured tokens. Witness the ascent, track the trends, and gauge the growth! Remember, when you hold a Sugarheads NFT, you have a stake in the Vault's treasures.                    </div>
                </div>
                <div className='vaultSection__schedule'>
                    <div className='vaultSection__schedule-buttons'>
                        {
                            contract ? contract.map((item, index) => {
                                return (<div key={index}>

                                    <div
                                        className={`vaultSection__schedule-buttons-btn ${activeButton === index + 1 ? 'active' : 'inactive'} ${animatedItem === index + 1 ? 'animate' : ''}`}
                                        onClick={() => {
                                            handleTap(index + 1);
                                            handleButtonClick(index + 1)
                                            handleContract(item, contract[index])
                                        }}
                                    >
                                        Vault 0{index + 1}
                                    </div>
                                </div>
                                )
                            }) : ""
                        }
                    </div>
                    {/* <div className='vaultSection__schedule-buttons'>
                        <div
                            className={`vaultSection__schedule-buttons-btn ${activeButton === 1 ? 'active' : 'inactive'} ${animatedItem === 1 ? 'animate' : ''}`}
                            onClick={() => {
                                handleTap(1);
                                handleButtonClick(1)
                            }}
                        >
                            Vault 01
                        </div>
                        <div
                            className={`vaultSection__schedule-buttons-btn ${activeButton === 2 ? 'active' : 'inactive'} ${animatedItem === 2 ? 'animate' : ''}`}
                            onClick={() => {
                                handleTap(2);
                                handleButtonClick(2)
                            }}
                        >
                            Vault 02
                        </div>
                    </div> */}
                    <div className={`vaultSection__schedule-graph ${graphAnimation}`}>
                        <div className='vaultSection__schedule-graph-firstBlock'>
                            <TimeButtons
                                timeIntervals={timeIntervals}
                                activeInterval={timeInterval}
                                onIntervalChange={handleTimeIntervalChange}
                                page='main'
                            />
                            <LineChart
                                width={Math.ceil(currentMaxWidth)}
                                height={Math.ceil(currentMaxHeight)}
                                data={filteredData}
                                key={activeButton + timeInterval}
                                margin={{ top: 0, right: 0, left: window.innerWidth < 1080 ? -20 : 0, bottom: 0 }}
                            >
                                <CartesianGrid stroke="#000" strokeDasharray="3 3" />
                                <XAxis
                                    dataKey={timeInterval === '24h' ? "time" : timeInterval === '7d' ? "date" : timeInterval === '30d' ? "date" : timeInterval === '1y' ? "date" : timeInterval === 'Max' ? "date" : ""}
                                    tickMargin={12}
                                    interval={timeInterval === '30d' ? 10 : timeInterval === '1y' ? 120 : timeInterval === 'Max' ? 241 :    timeInterval === '7d' ? 2 : timeInterval === '24h' ? 7 : 0}
                                    tick={(props) => {
                                        const formattedValue = props.tickFormatter(props.payload.value);
                                        const xShift = props.index ===  0 ? 30 : timeInterval === '1y' ? -40 : timeInterval === 'Max' ? -50 : timeInterval === '30d' ? -35 :  -25;
                                        if (window.innerWidth < 450 && window.innerWidth > 410) {
                                            if (props.index !== 0 && props.index !== 1 && props.index !== 3) {
                                                return null;
                                            }
                                        }
                                        else if (window.innerWidth < 409) {
                                            if (props.index !== 0 && props.index !== 3) {
                                                return null;
                                            }
                                        }

                                        return (
                                            <g>
                                                <text
                                                    x={props.x + xShift}
                                                    y={props.y}
                                                    dy={props.dy}
                                                    fontSize={window.innerWidth < 1080 ? '10px' : '16px'}
                                                    fontFamily="Russo One"
                                                    fontWeight="400"
                                                    textAnchor={props.textAnchor}
                                                    fill="rgba(0, 0, 0, 0.50)"
                                                    padding={'15px'}
                                                >

                                                    {/* {moment(props.payload.value).format()} */}
                                                    {formattedValue}
                                                </text>
                                            </g>
                                        );
                                    }
                                    }
                                    // domain={currentDomain}
                                    tickValues={xtickArray}
                                    tickFormatter={date => {
                                        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                        const d = new Date(date);
                                        
                                        const hours = String(d.getHours()).padStart(2, '0');
                                        const minutes = String(d.getMinutes()).padStart(2, '0');
                                        if (timeInterval === '30d' || timeInterval === '7d' || timeInterval === '24h') {
                                            return timeInterval === '24h' ? handleHour(date) : handleMonthDays(date);
                                        } else {
                                            return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
                                        }
                                    }}
                                />
                                <YAxis
                                    tickLine={false}
                                    width={90}
                                    tickMargin={window.innerWidth < 1080 ? 8 : 24}
                                    tick={{
                                        fill: 'rgba(0, 0, 0, 0.50)',
                                        fontSize: window.innerWidth < 1080 ? '10px' : '16px',
                                        fontFamily: 'Russo One',
                                        fontWeight: '400',
                                        lineHeight: window.innerWidth < 1080 ? '14px' : '24px'
                                    }}
                                />
                                <Tooltip content={(props) => <CustomTooltip {...props} formatDate={formatDate} />} />
                                <Line type="monotone" dataKey={timeInterval === '24h' ? "floorPrice" : timeInterval === '7d' ? 'avgFloorPrice' : timeInterval === '30d' ? "avgFloorPrice" : timeInterval === '1y' ? "avgFloorPrice" : timeInterval === 'Max' ? "avgFloorPrice" : ""} strokeWidth={1.6} stroke="#87FCB6" dot={false} activeDot={false} />
                                <Line type="monotone" dataKey={timeInterval === '24h' ? "highestPrice" : timeInterval === '7d' ? "avgHighestPrice" : timeInterval === '30d' ? "avgHighestPrice" : timeInterval === '1y' ? "avgHighestPrice" : timeInterval === 'Max' ? "avgHighestPrice" : ""} strokeWidth={1.6} stroke="#ffffff" dot={false} activeDot={false} />
                            </LineChart>
                        </div>
                        <div className='vaultSection__schedule-graph-information'>
                            <div className='vaultSection__schedule-graph-information-time'>
                                <div className='title'>
                                    the Vault will open in
                                </div>
                                <div className='date'>
                                    <div>{countdown?.days ? countdown?.days : "0"}</div>
                                    <div className='dots'>:</div>
                                    <div  className="timeCounter">{countdown?.hours ? countdown?.hours :"0" }</div>
                                    <div className='dots'>:</div>
                                    <div className="timeCounter">{countdown?.minutes ? countdown?.minutes :"0"}</div>
                                </div>
                                <div className='dateName'>
                                    <div className='item'>Days</div>
                                    <div className='item'>Hours</div>
                                    <div className='item'>Minutes</div>
                                </div>
                            </div>
                            <div className='vaultSection__schedule-graph-information-voltValues'>
                                <div className='value'>
                                    Vault Value:&nbsp; <span>${vaultValue ? vaultValue : "0"}</span>
                                </div>
                                <div className='value'>
                                    Backing Value:&nbsp; <span>${highestPrice ? highestPrice : "0"}</span>
                                </div>
                                <div className='value'>
                                    Floor Price:&nbsp; <span>${averagePrice ? averagePrice : "0"}</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='vaultSection__schedule-buttonWrap'>
                        <div onClick={() => handleTap('detail', '/analytics')} className={`vaultSection__schedule-buttonWrap-button ${animatedItem === 'detail' ? 'animate' : ''}`}>
                            View Detail
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VaultSection;
