import React, { useState } from "react";
import './style.scss';

import wingsImage from '../../../../images/analyticsPage/wingsImage.png';
import image1 from '../../../../images/analyticsPage/ownerItems/image1.png'
import image2 from '../../../../images/analyticsPage/ownerItems/image2.png'
import image3 from '../../../../images/analyticsPage/ownerItems/image3.png'
import image4 from '../../../../images/analyticsPage/ownerItems/image4.png'
import lockIcon from '../../../../images/analyticsPage/lockIcon.png';
import { useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { useSelector, useDispatch } from 'react-redux';
import { getContractNFT } from '../../../../store/UserSlices/UserSlices.js'
import { useEffect } from "react";

const ownerItemsArr = [image1, image2, image3, image4, image4, image3, image2, image1];

const OwnedItemsSection = ({ walletConnect }) => {
    const dispatch = useDispatch();
    const [nft,setNft]= useState(null)
    const contractAddress = useSelector((data) => data?.addressReducer);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { signer, walletProvider, WalletProviderType } = useWeb3ModalSigner();
    useEffect(() => {
        if (isConnected) {
            const data = {
                address: contractAddress?._id,
                provider: walletProvider?.getSigner(address)
            }
            dispatch(getContractNFT(data)).then((res)=>{
                setNft(res?.payload?.result?.nfts)
            })
        }
    }, [contractAddress, dispatch, isConnected])


    return (
        <div className='ownedItemsSectionWrapper'>
            <div className='ownedItemsSection limitWidth'>
                <div className={`ownedItemsSection__titleWrapper ${isConnected ? '' : 'blur'}`}>
                    <div className='ownedItemsSection__titleWrapper-title'>
                        Sugar Heads Owned
                        <div className='ownedItemsSection__titleWrapper-title-imageWrapper'>
                            <img src={wingsImage} alt='wingsImage' />
                        </div>
                    </div>
                </div>
                <div className={`ownedItemsSection__lockBlock ${isConnected ? 'hidden' : 'blur'} `}>
                    <img src={lockIcon} alt='lockIcon' />
                </div>
                <div className={`ownedItemsSection__itemsWrapper ${isConnected ? '' : 'hidden'}`}>
                    <div className='ownedItemsSection__itemsWrapper-count'>
                        Total items: {nft?.length}
                    </div>
                    <div className='ownedItemsSection__itemsWrapper-items'>
                        {nft && nft?.length>0 &&  nft?.map((item, key) => {
                            return (
                                <div key={key} className='ownedItemsSection__itemsWrapper-items-wrap'>
                                    <div className='ownedItemsSection__itemsWrapper-items-wrap-item'>
                                        <img src={item?.image_url} alt={item} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OwnedItemsSection;
