import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/mainPage/mainPage";
import AnalyticsPage from "./pages/analyticsPage/analyticsPage";
import PixelPage from "./pages/pixelsPage/PixelsPage";

function App() {
  const [walletConnect, setWalletConnect] = useState(false);
  const [activePage, setActivePage] = useState("main");
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    if ("ontouchstart" in window || navigator.maxTouchPoints) {
      document.body.classList.add("touch-device");
    }
  }, []);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <MainPage
              activePage={activePage}
              setActivePage={setActivePage}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              // setWalletConnect={setWalletConnect}
            />
          }
        ></Route>
        <Route
          path="/analytics"
          element={
            <AnalyticsPage
              walletConnect={walletConnect}
              setWalletConnect={setWalletConnect}
              activePage={activePage}
              setActivePage={setActivePage}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          }
        ></Route>
        <Route path="/pixels" element={<PixelPage />}></Route>
      </Routes>
    </>
  );
}

export default App;
