import axios from "axios";


export const getFeaturedPosts = async () => {

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/all-featured-posts?page=1&limit=10`)
        return response;

    } catch (error) {
        throw error;
    }
}

export const getPosts = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/all-posts?page=1&limit=100`);
        return response;
    }
    catch (err) {
        throw err;
    }
}

export const getTeams = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/all-team?page=1&limit=100`)
        return response;
    }
    catch (error) {
        throw error;
    }
}


export const hourlyGraph = async (data) => {
    try {

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/hourly-values/${data}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const monthlyGraph = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/monthly-values/${data}`);
        return response;
    } catch (error) {
        throw error
    }
}


export const weeklyGraph = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/weekly-values/${data}`);
        return response;

    } catch (error) {
        throw error
    }
}


export const yearlyGraph = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/yearly-values/${data}`);
        return response;
    }
    catch (error) {
        throw error
    }
}


export const maxGraph = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/max-values/${data}`)
        return response;
    }
    catch (error) {
        throw error
    }
}


export const getContracts = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/all-contracts?page=1&limit=100`);
        return response;
    } catch (error) {
        throw error
    }
}


export const getCurrencies = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/holdings/${data}`);
        return response;
    }
    catch (error) {
        throw error
    }
}


export const pieGraph = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pie/${data}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const lockTime = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/lock-time/${data}`);
        return response;
    }
    catch (error) {
        throw error
    }
}

export const getNFT = async (data, credential) => {
    try {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/nfts/${data}`, credential);
        return response;
    }
    catch (error) {
        throw error
    }
}

export const claimAmount = async (data, credential) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/claim-amount/${data}`, credential);
        return response;
    } catch (error) {
        throw error
    }
}


export const claimableNFT = async (data, credential) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/claimable-nfts/${data}`, credential);
        return response;
    }
    catch (error) {
        throw error
    }
}


export const contractABI = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/abi/${data}`);
        return response;
    }
    catch (error) {
        throw error
    }
}