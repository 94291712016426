import React, {useEffect} from "react";

import './style.scss';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import VoltSection from "./components/voltSection/voltSection";
import RewardsSection from "./components/rewardsSection/rewardsSection";
import OwnedItemsSection from "./components/ownedItemsSection/ownedItemsSection";
import useWindowSize from "../../hook/resizeWindow";
import {useLocation} from "react-router-dom";

const AnalyticsPage = ({walletConnect, setWalletConnect, setActivePage, activePage, menuOpen, setMenuOpen}) => {
    const windowSize = useWindowSize();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className='analyticsPage'>
            <Header setWalletConnect={setWalletConnect} activePage={activePage} setActivePage={setActivePage} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <VoltSection walletConnect={walletConnect} menuOpen={menuOpen} />
            <RewardsSection walletConnect={walletConnect} setWalletConnect={setWalletConnect} />
            <OwnedItemsSection walletConnect={walletConnect} />
            <Footer setActivePage={setActivePage} />
        </div>
    )
}

export default AnalyticsPage;
