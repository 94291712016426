import React, { useEffect, useState } from "react";
import './style.scss';
import { useWeb3Modal } from '@web3modal/ethers5/react'
import { useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';





const Button = ({ text }) => {
    const { open } = useWeb3Modal();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [animatedItem, setAnimatedItem] = useState(null);
    const baseClassName = `button button--connectwallet`;
    const animationClass = animatedItem === text ? 'animate' : '';
    const className = `${baseClassName} ${animationClass}`;

    const handleClick = async () => {
        setAnimatedItem(text);
        open();
        

        setTimeout(() => {
            setAnimatedItem(null);
        }, 500);
        
    }

    


    return (
        <div onClick={handleClick} className={className}>
            {text}
        </div>
    )
}

export default Button;
