import React, { useEffect, useRef, useState } from "react";
import './style.scss';
import crownIcon from '../../../../images/mainPage/crownIcon.png'
import Arrow1 from '../../../../images/Arrow1.svg'
import Arrow2 from '../../../../images/Arrow2.svg'
import nftHead from '../../../../images/mainPage/nftHead2.png'
import dopBG1080 from '../../../../images/mainPage/dopBG1080.png';
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { adminGetFeaturedPosts, adminGetPosts } from "../../../../store/UserSlices/UserSlices.js";
import moment from 'moment'

const customStyles = {
    zIndex: 2,
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "500px",
        zIndex: 999999999,
        fontFamily: "Russo One"

    },
};

// const sliderArr1 = [
//     {
//         date: '11 Jan',
//         title: 'NEW NFT COLLECTION',
//         description: 'Lorem ipsum dolor sit amet consectetur. Viverra massa pellentesque amet donec varius proin. Auctor sagittis viverra vestibulum risus senectus urna quis. Commodo facilisi.',
//     },
//     {
//         date: '11 Jan',
//         title: 'NEW NFT COLLECTION',
//         description: 'Lorem ipsum dolor sit amet consectetur. Viverra massa pellentesque amet donec varius proin. Auctor sagittis viverra vestibulum risus senectus urna quis. Commodo facilisi.',
//     },
//     {
//         date: '11 Jan',
//         title: 'NEW NFT COLLECTION',
//         description: 'Lorem ipsum dolor sit amet consectetur. Viverra massa pellentesque amet donec varius proin. Auctor sagittis viverra vestibulum risus senectus urna quis. Commodo facilisi.',
//     },
//     {
//         date: '11 Jan',
//         title: 'NEW NFT COLLECTION',
//         description: 'Lorem ipsum dolor sit amet consectetur. Viverra massa pellentesque amet donec varius proin. Auctor sagittis viverra vestibulum risus senectus urna quis. Commodo facilisi.',
//     },
//     {
//         date: '11 Jan',
//         title: 'NEW NFT COLLECTION',
//         description: 'Lorem ipsum dolor sit amet consectetur. Viverra massa pellentesque amet donec varius proin. Auctor sagittis viverra vestibulum risus senectus urna quis. Commodo facilisi.',
//     },
//     {
//         date: '11 Jan',
//         title: 'NEW NFT COLLECTION',
//         description: 'Lorem ipsum dolor sit amet consectetur. Viverra massa pellentesque amet donec varius proin. Auctor sagittis viverra vestibulum risus senectus urna quis. Commodo facilisi.',
//     },
// ]
// const sliderArr02 = [
//     {
//         image: nftHead,
//         title: 'New NFT Collection',
//         description: 'Lorem ipsum dolor sit amet consectetur. Tellus sapien a maecenas hendrerit eget egestas. Commodo non urna donec cursus et aliquam arcu iaculis felis.',
//     },
//     {
//         image: nftHead,
//         title: 'New NFT Collection',
//         description: 'Lorem ipsum dolor sit amet consectetur. Tellus sapien a maecenas hendrerit eget egestas. Commodo non urna donec cursus et aliquam arcu iaculis felis.',
//     },
//     {
//         image: nftHead,
//         title: 'New NFT Collection',
//         description: 'Lorem ipsum dolor sit amet consectetur. Tellus sapien a maecenas hendrerit eget egestas. Commodo non urna donec cursus et aliquam arcu iaculis felis.',
//     },
//     {
//         image: nftHead,
//         title: 'New NFT Collection',
//         description: 'Lorem ipsum dolor sit amet consectetur. Tellus sapien a maecenas hendrerit eget egestas. Commodo non urna donec cursus et aliquam arcu iaculis felis.',
//     },
//     {
//         image: nftHead,
//         title: 'New NFT Collection',
//         description: 'Lorem ipsum dolor sit amet consectetur. Tellus sapien a maecenas hendrerit eget egestas. Commodo non urna donec cursus et aliquam arcu iaculis felis.',
//     },
//     {
//         image: nftHead,
//         title: 'New NFT Collection',
//         description: 'Lorem ipsum dolor sit amet consectetur. Tellus sapien a maecenas hendrerit eget egestas. Commodo non urna donec cursus et aliquam arcu iaculis felis.',
//     },
// ]

const CookingSection = () => {
    const dispatch = useDispatch();
    const [sliderArr1, setSliderArr1] = useState(null);
    const [sliderArr2, setSliderArr2] = useState(null);
    const [modalData, setModalData] = useState(null);
    useEffect(() => {
        // Dispatch the action to fetch data for sliderArr1
        dispatch(adminGetPosts()).then((res) => {
            setSliderArr1(res?.payload?.result?.posts?.docs);
        });

        // Dispatch the action to fetch data for sliderArr2
        dispatch(adminGetFeaturedPosts()).then((res) => {
            setSliderArr2(res?.payload?.result?.posts?.docs);
            // setSliderArr2(sliderArr02)
        });
    }, [dispatch]);
    const [animatedItem, setAnimatedItem] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSlide2, setCurrentSlide2] = useState(0);
    const sliderRef = useRef(null);
    const sliderRef2 = useRef(null);
    const sliderArr1Length = window.innerWidth <= 1199 ? 2 : 3;

    const isLastSlide = currentSlide === sliderArr1?.length - sliderArr1Length;
    const isFirstSlide = currentSlide === 0;

    const isLastSlide2 = currentSlide2 === sliderArr2?.length - 1;
    const isFirstSlide2 = currentSlide2 === 0;

    const handleTap = (item) => {
        setAnimatedItem(item);
        setTimeout(() => {
            setAnimatedItem(null);
        }, 500);
    };



    const currentMaxWidth = calculateMaxWidth(window.innerWidth);

    function calculateMaxWidth(viewportWidth) {
        if (viewportWidth <= 1199) {
            return 2
        } else {
            return 3
        }
    }

    const handlePrev = (item) => {
        handleTap(item)
        if (!isFirstSlide && sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };
    const handleNext = (item) => {
        handleTap(item)
        if (currentSlide < sliderArr1?.length - sliderArr1Length) {
            if (sliderRef.current) {
                sliderRef.current.slickNext();
            }
        }
    };
    const handlePrev2 = (item) => {

        handleTap(item)
        if (!isFirstSlide2 && sliderRef2.current) {
            sliderRef2.current.slickPrev();
        }
    };
    const handleNext2 = (item) => {

        handleTap(item)
        if (currentSlide2 < sliderArr2?.length - 1) {
            if (sliderRef2.current) {
                sliderRef2.current.slickNext();
            }
        }
    };

   

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: currentMaxWidth,
        slidesToScroll: 1,
        speed: 500,
        vertical: true,
        verticalSwiping: true,
        swipe: window.innerWidth > 1080
    };
    const settings2 = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        horizontal: true,
        speed: 500,
        centerMode: true,
        centerPadding: '0',
        initialSlide: 0,
    };
   

    const [modal, setModal] = useState(false)

    const showHideClassName = modal ? "modal display-block" : "modal display-none";


    const showModal = (data) => {
        setModal(true)
        setModalData(data);
    };

    const hideModal = () => {
        setModal(false)
    };

    const handleClose = () => {
        hideModal();
    }

    useEffect(() => {
        if (modal) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
    
        // Cleanup: reset the overflow style when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [modal]);

    return (
        <>
            <div className={showHideClassName} onClick={handleClose} >
                <section className="modal-main">
                    <div style={{ textAlign: "center" }} >
                        <img src={modalData?.fileName ? `${process.env.REACT_APP_IMG_URL}/${modalData.fileName}` : ""} style={{ borderRadius: "15px" }} className="modal-img" />
                    </div>
                    <div> <h2>{modalData?.title} </h2></div>
                    <div style={{ margin: "10px 0" }}>
                        {moment(modalData?.publishDate).format('DD MMM')}
                    </div>
                    <div style={{ marginBottom: "60px" }} className="modal-description">{modalData?.description}</div>
                </section>
            </div>
            <div className='cookingSectionWrapper'>
                <div className='cookingSection limitWidth'>
                    <div className='cookingSection__firstBlock'>
                        <div className='cookingSection__firstBlock-title'>
                            What's Caramelizing?
                            <div className="img-wrapper">
                                <img src={crownIcon} />
                            </div>
                        </div>
                        <div className='cookingSection__firstBlock-description'>
                            Whisk through the latest from Sugarheads! This section is your honey pot of up-to-date information, capturing the essence of everything unfolding. Whether it's a new development, announcement, or just some sweet tidbits, if it's noteworthy, you'll find it melting right here.                    </div>
                    </div>
                    <div className='cookingSection__lastBlock'>
                        <div className='cookingSection__lastBlock-slider'>
                            <div className='cookingSection__lastBlock-slider-controls'>
                                <div className={`cookingSection__lastBlock-slider-controls-btn ${animatedItem === 'btn1' ? 'animate' : ''} ${isFirstSlide ? 'disabled-btn' : ''}`} onClick={() => handlePrev('btn1')}>
                                    <img src={Arrow2} />
                                </div>
                                <div className={`cookingSection__lastBlock-slider-controls-btn sec ${animatedItem === 'btn2' ? 'animate' : ''} ${isLastSlide ? 'disabled-btn' : ''}`} onClick={() => handleNext('btn2')}>
                                    <img src={window.innerWidth >= 768 ? Arrow1 : Arrow2} />
                                </div>
                            </div>

                            <Slider ref={sliderRef} {...settings} afterChange={current => setCurrentSlide(current)}>
                                {sliderArr1?.map((item, index) => {
                                    return (
                                        <div key={index} className='cookingSection__lastBlock-slider-item' onClick={()=>showModal(item)}>
                                            <div className='cookingSection__lastBlock-slider-item-date'>
                                                {moment(item.publishDate).format('DD MMM')}
                                            </div>
                                            <div className='cookingSection__lastBlock-slider-item-content'>
                                                <div className='title'>
                                                    {item.title}
                                                </div>
                                                <div className='description'>
                                                    {item.description}
                                                </div>
                                                {
                                                    item?.description?.length > 100 ? <span className="readmore" onClick={()=>showModal(item)}>Read more...</span> : ""
                                                }

                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <div className='cookingSection__lastBlock-dopBG'>
                            <img src={dopBG1080} alt='dopBG' />
                        </div>
                        <div className='cookingSection__lastBlock-content'>
                            <Slider ref={sliderRef2} {...settings2} afterChange={current => setCurrentSlide2(current)}>
                                {sliderArr2?.map((item, index) => {
                                    return (
                                        <div key={index}>

                                            <div className='cookingSection__lastBlock-content-image'>
                                                <img src={item.fileName ? `${process.env.REACT_APP_IMG_URL}/${item.fileName}` : ""} style={{ borderRadius: "15px" }} />
                                            </div>


                                            <div className='cookingSection__lastBlock-content-title'>
                                                {item.title}
                                            </div>
                                            <div className='cookingSection__lastBlock-content-description'>
                                                {item.description}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                            <div className='cookingSection__lastBlock-content-controls'>
                                <div className={`cookingSection__lastBlock-content-controls-btn ${animatedItem === 'btn3' ? 'animate' : ''} ${isFirstSlide2 ? 'disabled-btn' : ''}`} onClick={() => handlePrev2('btn3')}>
                                    <img src={Arrow1} />
                                </div>
                                <div className={`cookingSection__lastBlock-content-controls-btn ${animatedItem === 'btn4' ? 'animate' : ''} ${isLastSlide2 ? 'disabled-btn' : ''}`} onClick={() => handleNext2('btn4')}>
                                    <img src={Arrow2} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CookingSection;
