import React, {useState} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const PieChartComponent = ({ array, walletConnect, currentWidth }) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeItem, setActiveItem] = useState(null);
    
    const handleMouseEnter = (_, index) => {
        setActiveIndex(index);
        setActiveItem(data[index]);
    };

    const Modal = ({ item }) => {
        return (
            <div className={`pieChartModal ${activeItem ? 'pieChartModalActive' : ''}`}>
                <div className='pieChartModal__first'>{item.name}</div>
                <div className='pieChartModal__second'>Value: {item.value.toFixed(2)}%</div>
            </div>
        )
    };
    const handleMouseLeave = () => {
        setActiveIndex(-1);
        setActiveItem(null);
    };
    const outerRadius = calculateOuterRadius(currentWidth);

    function calculateOuterRadius(viewportWidth) {
        if (viewportWidth >= 1440 && viewportWidth <= 1920) {
            const minWidthValue = 232;
            const maxWidthValue = 280;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1440) / (1920 - 1440));
        } else if (viewportWidth >= 1081 && viewportWidth <= 1440) {
            const minWidthValue = 160;
            const maxWidthValue = 232;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 1081) / (1440 - 1081));
        } else if (viewportWidth >= 480 && viewportWidth <= 768) {
            const minWidthValue = 160;
            const maxWidthValue = 232;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 480) / (768 - 480));
        } else if (viewportWidth >= 320 && viewportWidth <= 480) {
            const minWidthValue = 140;
            const maxWidthValue = 160;
            return minWidthValue + (maxWidthValue - minWidthValue) * ((viewportWidth - 320) / (480 - 320));
        } else if (viewportWidth > 1920) {
            return 280;
        } else if (viewportWidth <= 1080) {
            return 232;
        }
        return null;
    }

    function processDataForChart(dataArray) {
        if (dataArray?.length > 6) {
            const sortedArray = dataArray.sort((a, b) => b.balance - a.balance);
            const topFive = sortedArray.slice(0, 4);
            const othersPurse = sortedArray.slice(4)?.reduce((acc, item) => acc + item.balance, 0);

            return [
                ...topFive?.map(item => ({
                    name: item.contract_ticker_symbol.toUpperCase(),
                    value: (item.balance / totalPurse) * 100,
                })),
                { name: 'OTHERS', value: (othersPurse / totalPurse) * 100 }
            ];
        }

        return dataArray?.map(item => ({
            name: item.contract_ticker_symbol.toUpperCase(),
            value: (item.balance / totalPurse) * 100,
        }));
    }
    const totalPurse = array?.reduce((acc, item) => acc + item.balance, 0);
    const data = processDataForChart(array);

    const COLORS = ['#FF8042', '#8884d8', '#FF6CAE', '#82CA9D', '#FFEB6B', '#0088FE'];

    const RADIAN = Math.PI / 180;
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
            cx, cy, midAngle, startAngle, endAngle,
            innerRadius, outerRadius, fill, payload, percent
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);

        return (
            <g>
                <path d={`M${cx},${cy}L${cx + (outerRadius) * cos},${cy + (outerRadius) * sin}
                A${outerRadius},${outerRadius} 0 0 1 ${cx + (outerRadius) * Math.cos(-RADIAN * endAngle)},${cy + (outerRadius) * Math.sin(-RADIAN * endAngle)}
                L${cx + (innerRadius) * Math.cos(-RADIAN * endAngle)},${cy + (innerRadius) * Math.sin(-RADIAN * endAngle)}
                A${innerRadius},${innerRadius} 0 0 0 ${cx + (innerRadius) * cos},${cy + (innerRadius) * sin}
                Z`}
                      fill={fill}
                />
            </g>
        );
    };
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
        if (percent < 0.06) return null;

        const radiusCoefficient = currentWidth <= 480 ? 0.7 : 0.7;
        const radius = innerRadius + (outerRadius - innerRadius) * radiusCoefficient;
        const displayPercent = (percent * 100).toFixed(0) ;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <g>
                <text className='gText' x={x} y={currentWidth <= 600 ? y - 5 : y - 10} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${displayPercent}%`}
                </text>
                <text className='gText2' x={x} y={y + 10} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {payload.name}
                </text>
            </g>
        );
    };

    return (
        <>
            {activeItem && <Modal item={activeItem} />}
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={outerRadius}
                        fill="#8884d8"
                        dataKey="value"
                        stroke="black"
                        strokeWidth={4}
                        activeShape={renderActiveShape}
                        isAnimationActive={false}
                        activeIndex={-1}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} stroke="black" strokeWidth={4} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </>
    );
};

export default PieChartComponent;
