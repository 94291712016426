import React, {useEffect} from "react";
import './style.scss';
import Header from "../../components/header/header";
import FirstSection from "./components/firstSection/firstSection";
import SauseSecretSection from "./components/sauseSecretSection/sauseSecretSection";
import CookingSection from "./components/cookingSection/cookingSection";
import VaultSection from "./components/vaultSection/vaultSection";
import TeamSection from "./components/teamSection/teamSection";
import Footer from "../../components/footer/footer";
import {Element, scroller} from 'react-scroll';
import {useLocation} from "react-router-dom";
import useWindowSize from "../../hook/resizeWindow";

const MainPage = ({activePage, setActivePage, menuOpen, setMenuOpen, setWalletConnect}) => {
    const windowSize = useWindowSize();
    const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            scroller.scrollTo(location.state.scrollTo, {
                duration: 500,
                delay: 0,
                smooth: true,
                offset: location.state.scrollTo === 'cookingSection' ? -200 : -100
            });
        }
    }, [location]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <div className='mainPage'>
            <Header
                setWalletConnect={setWalletConnect}
                activePage={activePage}
                setActivePage={setActivePage}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
            />
            <FirstSection menuOpen={menuOpen} />
            <SauseSecretSection />
            <Element name="cookingSection">
                <CookingSection />
            </Element>
            <Element name="vaultSection">
                <VaultSection />
            </Element>
            <Element name="teamSection">
                <TeamSection />
            </Element>
            <Footer setActivePage={setActivePage} />
        </div>
    )
}

export default MainPage;
