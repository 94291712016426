import React from "react";
import Slider from "react-slick";
import './style.scss';
import newnft1 from '../../../../images/mainPage/newnft1.jpg';
import newnft2 from '../../../../images/mainPage/newnft2.jpg';
import newnft3 from '../../../../images/mainPage/newnft3.jpg';
import newnft4 from '../../../../images/mainPage/newnft4.jpg';
import newnft5 from '../../../../images/mainPage/newnft5.jpg';
import newnft6 from '../../../../images/mainPage/newnft6.jpg';
import newnft7 from '../../../../images/mainPage/newnft7.jpg';
import newnft8 from '../../../../images/mainPage/newnft8.jpg';
import newnft9 from '../../../../images/mainPage/newnft9.jpg';
import newnft10 from '../../../../images/mainPage/newnft10.jpg';
import newnft11 from '../../../../images/mainPage/newnft11.jpg';
import newnft12 from '../../../../images/mainPage/newnft12.jpg';
import newnft13 from '../../../../images/mainPage/newnft13.jpg';
import newnft14 from '../../../../images/mainPage/newnft14.jpg';
import newnft15 from '../../../../images/mainPage/newnft15.jpg';
import newnft16 from '../../../../images/mainPage/newnft16.jpg';
import newnft17 from '../../../../images/mainPage/newnft17.jpg';
import newnft18 from '../../../../images/mainPage/newnft18.jpg';
import newnft19 from '../../../../images/mainPage/newnft19.jpg';
import newnft20 from '../../../../images/mainPage/newnft20.jpg';
import nft1 from '../../../../images/mainPage/nft1.png';
import nft2 from '../../../../images/mainPage/nft2.png';
import nft3 from '../../../../images/mainPage/nft3.png';
import nftImg from '../../../../images/mainPage/nftImg.png';
import nftImg2 from '../../../../images/mainPage/nftImg2.png';
import nftImg3 from '../../../../images/mainPage/nftImg3.png';
import nftImg4 from '../../../../images/mainPage/nftImg4.png';
import crossIcon from '../../../../images/mainPage/crossIcon.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SauseSecretSection = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        speed: 1500,
        vertical: true,
        verticalSwiping: true,
        swipe: window.innerWidth>1080
    };

    return (
        <div className='sauseSecretWrapper'>
            <div className='sauseSecret limitWidth'>
                <div className='sauseSecret__nftBlock'>
                    <div className={`abs`}></div>
                    <div className={`abs2`}></div>
                    <Slider {...settings} rtl={true}>
                        <img src={newnft1} />
                        <img src={newnft2} />
                        <img src={newnft3} />
                        <img src={newnft4} />
                        <img src={newnft13} />
                        <img src={newnft16} />
                        <img src={newnft19} />
                        <img src={nft2} />
                        <img src={nftImg3} />
                    </Slider>
                    <Slider {...settings}>
                        <img src={newnft5} />
                        <img src={newnft6} />
                        <img src={newnft7} />
                        <img src={newnft8} />
                        <img src={newnft14} />
                        <img src={newnft17} />
                        <img src={newnft20} />
                        <img src={nft3} />
                        <img src={nftImg} />
                        <img src={nftImg4} />
                    </Slider>
                    <Slider className='Slider3' {...settings} rtl={true}>
                        <img src={newnft9} />
                        <img src={newnft10} />
                        <img src={newnft11} />
                        <img src={newnft12} />
                        <img src={newnft15} />
                        <img src={newnft18} />
                        <img src={nft1} />
                        <img src={nftImg2} />
                        <img src={newnft15} />
                    </Slider>
                </div>
                <div className='sauseSecret__contentBlock'>
                    <div className='sauseSecret__contentBlock-title'>
                        The Not-So-Secret Ingredient
                        <img src={crossIcon}/>
                    </div>
                    <div className='sauseSecret__contentBlock-description'>
                        Sugar Heads represent the world's first NFTs, each uniquely backed by a basket of time-locked crypto tokens. This innovative approach ensures the collection remains untouchable and genuine to the utility we wanted to provide: Let you speculate on a beautiful NFT that will actually pay it back.<br /><br />

                        When you mint and trade these gems, you're securing more than just an iconic fragment of Sugar Heads culture. You're locking in a token-anchored assurance that a share of the vault's proceeds is rightfully yours. Time until Vault unlocks: (Insert Countdown Timer Hyperlinked to the Vault)
                        </div>
                </div>
            </div>
        </div>
    )
}

export default SauseSecretSection;
