import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
    getFeaturedPosts,
    getTeams,
    getPosts,
    hourlyGraph,
    monthlyGraph,
    weeklyGraph,
    yearlyGraph,
    maxGraph,
    getContracts,
    getCurrencies,
    pieGraph,
    lockTime,
    getNFT,
    claimAmount,
    claimableNFT,
    contractABI
} from '../../services/SugarHeads'



export const adminGetFeaturedPosts = createAsyncThunk(
    'admin/get-featured-posts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getFeaturedPosts(data);

            return response.data;
        }
        catch (err) {

            if (err.response.status === 400) {


            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const adminGetPosts = createAsyncThunk(
    'admin/get-posts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getPosts(data);

            return response.data;
        }
        catch (err) {

            if (err.response.status === 400) {


            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const adminGetFeaturedTeams = createAsyncThunk(
    'admin/get-featured-teams',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getTeams(data);

            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {

                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getHourlyGraph = createAsyncThunk(
    'admin/hourly-graph',
    async (data, { rejectWithValue }) => {
        try {
            const response = await hourlyGraph(data);
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {

                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)

export const getMonthlyGraph = createAsyncThunk(
    'admin/monthly-graph',
    async (data, { rejectWithValue }) => {
        try {
            const response = await monthlyGraph(data);
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }

    }
)

export const getWeeklyGraph = createAsyncThunk(
    'admin/weekly-graph',
    async (data, { rejectWithValue }) => {
        try {
            const response = await weeklyGraph(data);
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getYearlyGraph = createAsyncThunk(
    'admin/yearly-graph',
    async (data, { rejectWithValue }) => {
        try {
            const response = await yearlyGraph(data);
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getMaxGraph = createAsyncThunk(
    'admin/max-graph',
    async (data, { rejectWithValue }) => {
        try {
            const response = await maxGraph(data);
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getContract = createAsyncThunk(
    'admin/contracts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getContracts();
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)

export const wallectCurrencies = createAsyncThunk(
    'admin/currencies',
    async (data, { rejectWithValue }) => {
        try {
            let response = await getCurrencies(data);
            return response.data;
        }
        catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)

export const PieGraphValues = createAsyncThunk(
    'admin/piegraph',
    async (data, { rejectWithValue }) => {
        try {
            let response = await pieGraph(data);
            return response.data;
        } catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)

export const contractTime = createAsyncThunk(
    'admin/contract/:id',
    async (data, { rejectWithValue }) => {
        try {
            let response = await lockTime(data);
            return response.data;
        } catch (err) {
            // console.error('Network Error:', err.response.data.result.details.MESSAGE);
            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {
            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getContractNFT = createAsyncThunk(
    'admin/get-nft',
    async (data, { rejectWithValue }) => {
        try {
            const credential = {
                _isSigner: data?.provider?._isSigner,
                address: data?.provider?._address,
                provider: null
            }

            let response = await getNFT(data?.address, credential);

            return response.data;
        } catch (err) {

            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getClaimAmount = createAsyncThunk(
    'admin/claim-amount',
    async (data, { rejectWithValue }) => {
        try {
            const credential = {
                _isSigner: data?.provider?._isSigner,
                address: data?.provider?._address,
                provider: null
            }

            let response = await claimAmount(data?.address, credential);

            return response.data;
        } catch (err) {

            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)

export const getClaimNFT = createAsyncThunk(
    'admin/claim-nft',
    async (data, { rejectWithValue }) => {
        try {
            const credential = {
                _isSigner: data?.provider?._isSigner,
                address: data?.provider?._address,
                provider: null
            }

            let response = await claimableNFT(data?.address, credential);

            return response.data;
        } catch (err) {

            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)


export const getContractABI = createAsyncThunk(
    'admin/contractABI',
    async (data, { rejectWithValue }) => {
        try {
            let response = await contractABI(data);
            return response.data;
        } catch (err) {

            if (err.response.status === 400) {
                // toast.error(err?.response?.data?.result?.details?.MESSAGE)
            } if (err.response.status === 401) {

            }
            return rejectWithValue(err.response.data);
        }
    }
)



export const userSlices = createSlice({
    name: 'user',

    initialState: {
        data: [],
        time: [],
        nft: [],
        loading: false,
        error: null,


    },
    reducers: {},
    extraReducers: (builder) => {
        // --- get featured posts ---
        builder.addCase(adminGetFeaturedPosts.pending, (state) => {
            state.loading = true;
        })
            .addCase(adminGetFeaturedPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(adminGetFeaturedPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })



        builder.addCase(adminGetFeaturedTeams.pending, (state) => {
            state.loading = true;
        })
            .addCase(adminGetFeaturedTeams.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(adminGetFeaturedTeams.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


        builder.addCase(adminGetPosts.pending, (state) => {
            state.loading = true;
        })
            .addCase(adminGetPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(adminGetPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


        builder.addCase(getHourlyGraph.pending, (state) => {
            state.loading = true;
        })
            .addCase(getHourlyGraph.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getHourlyGraph.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

        builder.addCase(getMonthlyGraph.pending, (state) => {
            state.loading = true;
        })
            .addCase(getMonthlyGraph.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getMonthlyGraph.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

        builder.addCase(getWeeklyGraph.pending, (state) => {
            state.loading = true;
        })
            .addCase(getWeeklyGraph.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getWeeklyGraph.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


        builder.addCase(getYearlyGraph.pending, (state) => {
            state.loading = true;
        })
            .addCase(getYearlyGraph.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getYearlyGraph.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })



        builder.addCase(getMaxGraph.pending, (state) => {
            state.loading = true;
        })
            .addCase(getMaxGraph.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getMaxGraph.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


        builder.addCase(getContract.pending, (state) => {
            state.loading = true;
        })
            .addCase(getContract.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getContract.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

        builder.addCase(wallectCurrencies.pending, (state) => {
            state.loading = true;
        })
            .addCase(wallectCurrencies.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(wallectCurrencies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


        builder.addCase(contractTime.pending, (state) => {
            state.loading = true;
        })
            .addCase(contractTime.fulfilled, (state, action) => {
                state.loading = false;
                state.time = action.payload;
            })
            .addCase(contractTime.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


        builder.addCase(getContractNFT.pending, (state) => {
            state.loading = true;
        })
            .addCase(getContractNFT.fulfilled, (state, action) => {
                state.loading = false;
                state.nft = action.payload;
            })
            .addCase(getContractNFT.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

        builder.addCase(getClaimAmount.pending, (state) => {
            state.loading = true;
        })
            .addCase(getClaimAmount.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getClaimAmount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

        builder.addCase(getClaimNFT.pending, (state) => {
            state.loading = true;
        })
            .addCase(getClaimNFT.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getClaimNFT.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

        builder.addCase(getContractABI.pending, (state) => {
            state.loading = true;
        })
            .addCase(getContractABI.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getContractABI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

    },
});

export default userSlices.reducer;
